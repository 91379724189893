import { ApiEndpoints } from '../../enums'
import { axiosClient } from 'helpers'
import { UpdateCompanyInformationRequest, UpdateUserInformationRequest } from '../../types'

export const useUpdateUserInformation = () => {
  const updateUserInformation = async (
    token: string | null,
    requestBody: UpdateUserInformationRequest,
  ) => {
    try {
      const response = await axiosClient(token).put(ApiEndpoints.USER, requestBody)

      return response
    } catch (error) {
      throw new Error('Login error')
    }
  }

  return { updateUserInformation }
}
