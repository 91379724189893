import { LoginRequest, LoginResponse } from 'types'
import { ApiEndpoints } from 'enums'
import { axiosClient } from 'helpers'
import axios, { AxiosError } from 'axios'

export const useLoginUser = () => {
  const loginUser = async ({ email, password }: LoginRequest) => {
    try {
      const requestBody: LoginRequest = { email, password }
      const response = await axiosClient(null).post(ApiEndpoints.LOGIN, requestBody)
      return response.data as LoginResponse
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(error.response.data.error)
      }
      throw new Error('Login error')
    }
  }

  return { loginUser }
}
