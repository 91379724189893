import useSWR from "swr";
import { Transcription } from "../../types/transcription.types";
import { ApiEndpoints } from "../../enums";
import { axiosFetcher } from "helpers";
import { AxiosError } from "axios";

export const useFetchTranscription = (id: string, token: string | null) => {
  const { data, error, isLoading, mutate } = useSWR<Transcription, AxiosError>(
    `${ApiEndpoints.TRANSCRIPTION}/${id}`,
    axiosFetcher(token)
  );

  return { transcription: data, error, isLoading, mutate };
};
