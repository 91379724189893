import { axiosClient } from "./axiosClient";

export const axiosFetcher = (token: string | null) => {
  const axiosFetcher = async (url: string) => {
    const response = await axiosClient(token).get(url);
    return response.data;
  };

  return axiosFetcher;
};
