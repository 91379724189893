import { Spin } from "antd";

const ContentLoader = () => {
  return (
    <div className=" flex justify-center items-center">
      <Spin tip="Loading" size="large" />
    </div>
  );
};

export default ContentLoader;
