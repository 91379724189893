import { ApiEndpoints } from 'enums'
import { axiosClient, getMp3Duration } from 'helpers'
import { CreateTranscriptionRequest } from 'types'
import axios from 'axios'

export const useCreateTranscription = (token: string | null) => {
  const createTranscription = async ({
    file,
    languageCode,
    speakerDetection,
    transcriptionName,
  }: CreateTranscriptionRequest) => {
    const mp3Duration = await getMp3Duration(file)
    const flooredSeconds = Math.floor(mp3Duration)

    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('languageCode', languageCode)
      formData.append('speakerDetection', speakerDetection.toString())
      formData.append('transcriptionName', transcriptionName)
      formData.append('durationInSeconds', flooredSeconds.toString())
      formData.append('fileSizeInBytes', file.size.toString())

      const response = await axiosClient(token).post(ApiEndpoints.TRANSCRIPTION, formData)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(error.response.data.error)
      }
      throw new Error('Login error')
    }
  }

  return { createTranscription }
}
