import React, { FC } from 'react'

interface Props {
  title: string
  href: string
}

const FooterLink: FC<Props> = ({ href, title }) => {
  return (
    <a href={href}>
      <p className="text-primaryBlue hover:text-primaryRed">{title}</p>
    </a>
  )
}

export default FooterLink
