import { RegisterForm } from '../components/Register'
import { AppPaths } from '../enums'
import { useNavigate } from 'react-router-dom'

const Register = () => {
  const navigate = useNavigate()
  const handleLoginClick = () => {
    navigate(AppPaths.LOGIN)
  }

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex justify-center items-center flex-col w-[750px] shadow-default rounded-3xl bg-white px-12 pt-28 pb-20">
        <p className="mb-20 text-4xl text-center font-semibold">Zarejestruj się</p>
        <RegisterForm />
        <p className="text-black mt-20">
          Masz konto?{' '}
          <span className="font-semibold cursor-pointer" onClick={handleLoginClick}>
            Zaloguj się
          </span>
        </p>
      </div>
    </div>
  )
}

export default Register
