import { TranscriptSentence } from "assemblyai";
import { SpeakerBlock } from "types";

export const getSpeakerBlockFormat = (
  assemblyaiSentences: TranscriptSentence[]
): SpeakerBlock[] => {
  const speakerBlocks: SpeakerBlock[] = [];
  const speakerBlock: SpeakerBlock = {
    sentences: [],
    speaker: assemblyaiSentences[0].speaker || "",
  };

  if (assemblyaiSentences[0].speaker) {
    assemblyaiSentences.forEach((sentence, index) => {
      if (index === assemblyaiSentences.length - 1) {
        speakerBlock.sentences.push({ ...sentence });
        speakerBlocks.push({ ...speakerBlock });
        return;
      }

      if (sentence.speaker === speakerBlock.speaker) {
        speakerBlock.sentences.push({ ...sentence });
      } else {
        speakerBlocks.push({ ...speakerBlock });
        speakerBlock.speaker = sentence.speaker || "";
        speakerBlock.sentences = [{ ...sentence }];
      }
    });

    return speakerBlocks;
  }

  speakerBlock.sentences = assemblyaiSentences;
  speakerBlock.speaker = "A";
  speakerBlocks.push({ ...speakerBlock });
  return speakerBlocks;
};
