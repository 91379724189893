import { axiosFetcher } from 'helpers'
import { AxiosError } from 'axios'
import useSWR from 'swr'
import { ApiEndpoints } from '../../enums'
import { Product, Subscription } from '../../types'

export const useFetchSubscription = (token: string | null) => {
  const { data, error, isLoading, mutate } = useSWR<Subscription, AxiosError>(
    `${ApiEndpoints.SUBSCRIPTION}`,
    axiosFetcher(token),
  )

  return { subscription: data, error, isLoading, mutate }
}
