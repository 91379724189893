export function convertMillisecondsToTime(milliseconds: number) {
  // Create a new Date object with the milliseconds
  const date = new Date(milliseconds);

  // Extract hours, minutes, and seconds from the Date object
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  // Format as HH:mm:ss
  const formattedTimeNoHours = `${minutes}:${seconds}`;
  const formattedTimeWithHours = `${hours}:${minutes}:${seconds}`;

  return hours === "00" ? formattedTimeNoHours : formattedTimeWithHours;
}
