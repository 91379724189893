import React, { FC, useState } from 'react'
import { Checkbox, Form, Input } from 'antd'
import { CompanyFormValues, CompanyInformation, UserFormValues } from '../../../types'
import { getCompanyFormRules } from '../../../helpers'
import { useUpdateCompanyInformation } from '../../../hooks'
import { useAppSelector } from '../../../store/hooks'
import { NotificationInstance } from 'antd/lib/notification/interface'
import { useFetchInvoices } from '../../../hooks/request'

interface Props {
  companyInfo: CompanyInformation
  toast: NotificationInstance
}

const CompanyForm: FC<Props> = ({ companyInfo, toast }) => {
  const { token } = useAppSelector(state => state.auth)
  const { updateCompanyInformation } = useUpdateCompanyInformation()
  const [disabled, setDisabled] = useState<boolean>(companyInfo.nip === null)
  const [form] = Form.useForm() // Get the form instance
  const { companyName, nip, city, address, postalCode } = companyInfo
  const { addressRules, cityRules, companyNameRules, nipRules, postalCodeRules } =
    getCompanyFormRules()

  const onFinish = async (formData: CompanyFormValues) => {
    try {
      await updateCompanyInformation(token, formData)
      toast.success({ message: 'Dane zostały zaktualizowane' })
    } catch {
      toast.error({ message: 'Dane nie zostały zaktualizowane' })
    }
  }

  const onCheckboxChange = (e: any) => {
    if (!e.target.checked) {
      form.resetFields()
    }
    setDisabled(!e.target.checked)
  }

  return (
    <div className="flex flex-col gap-6 py-8 w-1/2">
      <Checkbox onChange={onCheckboxChange} defaultChecked={!disabled}>
        <p className="text-xs text-blackText">
          Konto firmowe <span className="text-gray">(potrzebuję faktury VAT)</span>
        </p>
      </Checkbox>
      <p>Dane do faktury</p>
      <Form form={form} disabled={disabled} onFinish={onFinish} className="w-full">
        <p className="mb-2">
          Nazwa firmy <span className="text-red-500">*</span>
        </p>
        <Form.Item<CompanyFormValues>
          name="companyName"
          rules={companyNameRules}
          initialValue={companyName}
        >
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Nazwa firmy'}
            data-testid="email"
          />
        </Form.Item>
        <p className="mb-2">
          NIP <span className="text-red-500">*</span>
        </p>
        <Form.Item<CompanyFormValues> name="nip" rules={nipRules} initialValue={nip}>
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'NIP'}
            data-testid="password"
          />
        </Form.Item>
        <p className="mb-2">
          Adres <span className="text-red-500">*</span>
        </p>
        <Form.Item<CompanyFormValues> name="address" rules={addressRules} initialValue={address}>
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Adres'}
            data-testid="password"
          />
        </Form.Item>
        <p className="mb-2">
          Kod pocztowy <span className="text-red-500">*</span>
        </p>
        <Form.Item<CompanyFormValues>
          name="postalCode"
          rules={postalCodeRules}
          initialValue={postalCode}
        >
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Kod pocztowy'}
            data-testid="password"
          />
        </Form.Item>
        <p className="mb-2">
          Miejscowość <span className="text-red-500">*</span>
        </p>
        <Form.Item<CompanyFormValues> name="city" rules={cityRules} initialValue={city}>
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Miejscowość'}
            data-testid="password"
          />
        </Form.Item>
        <button
          type="submit"
          className="bg-primaryBlue border-2 border-primaryBlue py-3 px-10 font-bold rounded-xl box-border w-1/3 text-white mt-6 hover:bg-transparent hover:text-primaryBlue transition duration-300"
        >
          Zapisz
        </button>
      </Form>
    </div>
  )
}

export default CompanyForm
