import { Segmented } from "antd";
import { ContentLoader } from "components/common";
import { useFetchAssemblyaiSentences } from "hooks";
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { Transcription } from "types";
import TranscriptionProcessingLoader from "../TranscriptionProcessingLoader";
import {
  TranscriptionSentenceContent,
  TranscriptionSpeakerBlockContent,
} from "..";

interface Props {
  transcription: Transcription;
}

const TranscriptionContent: FC<Props> = ({ transcription }) => {
  const [displayValue, setDisplayValue] = useState("speakerBlocks");
  const { token } = useAppSelector((state) => state.auth);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { assemblyaiSentences, isLoading } = useFetchAssemblyaiSentences(
    transcription.assemblyAiId,
    token,
    shouldRefresh
  );

  useEffect(() => {
    if ((assemblyaiSentences?.length === 0 || !assemblyaiSentences) && !isLoading) {
      setShouldRefresh(true);
    }
    if (assemblyaiSentences && assemblyaiSentences.length !== 0) setShouldRefresh(false);
  }, [assemblyaiSentences]);

  if (isLoading && !shouldRefresh) return <ContentLoader />;

  if (assemblyaiSentences?.length === 0 || !assemblyaiSentences || shouldRefresh)
    return <TranscriptionProcessingLoader />;

  return (
    <>
      <Segmented
        options={[
          { label: "Bloki mówcy", value: "speakerBlocks" },
          { label: "Zdania", value: "sentences" },
        ]}
        onChange={(value) => {
          setDisplayValue(value.toString());
        }}
        className="mb-4"
        size="large"
      />
      {displayValue === "sentences" ? (
        <TranscriptionSentenceContent
          assemblyaiSentences={assemblyaiSentences}
        />
      ) : null}
      {displayValue === "speakerBlocks" ? (
        <TranscriptionSpeakerBlockContent
          assemblyaiSentences={assemblyaiSentences}
        />
      ) : null}
    </>
  );
};

export default TranscriptionContent;
