import { ContentLoader, ErrorContent } from 'components/common'
import { useFetchCompanyInformation, useFetchMe, useFetchTranscriptions } from 'hooks'
import { useAppSelector } from 'store/hooks'
import { CompanyForm, UserForm } from '../components/Profile'
import { notification } from 'antd'

const Profile = () => {
  const { token } = useAppSelector(state => state.auth)
  const { isLoading, user, error } = useFetchMe(token)
  const [toast, contextHolder] = notification.useNotification()
  const {
    isLoading: isCompanyLoading,
    error: companyError,
    companyInfo,
  } = useFetchCompanyInformation(token)

  if (isLoading || isCompanyLoading) return <ContentLoader />

  if (error || !user || companyError || !companyInfo) return <ErrorContent />

  if (isLoading) return <ContentLoader />

  return (
    <div className="py-8 w-full">
      {contextHolder}
      <p className="text-3xl text-primaryBlue font-semibold">Moje dane</p>
      <div className="w-2/3 flex flex-row gap-24">
        <UserForm user={user} toast={toast} />
        <CompanyForm companyInfo={companyInfo} toast={toast} />
      </div>
    </div>
  )
}

export default Profile
