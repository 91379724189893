import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'
export const getResetPasswordFormRules = () => {
  const email: Rule[] = [
    {
      required: true,
      message: 'Pole wymagane',
    },
    {
      max: 45,
      message: 'Maksymalna liczba znaków to 45',
    },
    { type: 'email', message: 'Niepoprawny email' },
  ]

  return { email }
}
