import React from 'react'

const HowWorksSvg = () => {
  return (
    <svg
      className="w-full"
      width="990"
      height="44"
      viewBox="0 0 990 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 21.9999C283.491 90.4793 708.46 -47.3086 989.5 21.9999"
        stroke="#A2A2A2"
        stroke-width="3"
        stroke-dasharray="5 15"
      />
    </svg>
  )
}

export default HowWorksSvg
