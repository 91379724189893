import useSWR from 'swr'
import { CompanyInformation } from '../../types'
import { AxiosError } from 'axios/index'
import { ApiEndpoints } from '../../enums'
import { axiosFetcher } from '../../helpers'

export const useFetchCompanyInformation = (token: string | null) => {
  const { data, error, isLoading, mutate } = useSWR<CompanyInformation, AxiosError>(
    `${ApiEndpoints.STRIPE}/company-information`,
    axiosFetcher(token),
  )

  return { companyInfo: data, error, isLoading, mutate }
}
