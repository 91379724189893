import { Button, Empty } from 'antd'
import { TranscriptionTile } from 'components/Transcriptions'
import { ContentLoader, ErrorContent } from 'components/common'
import { AppPaths } from 'enums'
import { useFetchMe, useFetchProducts, useFetchTranscriptions } from 'hooks'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import {
  NonSubscriptionInfo,
  ProfileInfo,
  SubscriptionActiveContent,
  SubscriptionInfo,
  SubscriptionProductTile,
} from '../components/Subscription'
import { useCreatePortalSession, useFetchSubscription } from '../hooks/request'
import NoSubscriptionContent from '../components/Subscription/NoSubscriptionContent/NoSubscriptionContent'
import AvailableMinutesInfo from '../components/Subscription/AvailableMinutesInfo'

const Subscription = () => {
  const { token } = useAppSelector(state => state.auth)
  const { isLoading, user, error } = useFetchMe(token)
  const { products, isLoading: isProductLoading } = useFetchProducts(token)
  const { subscription, isLoading: isSubscriptionLoading } = useFetchSubscription(token)

  if (isLoading || isSubscriptionLoading || isProductLoading) return <ContentLoader />
  if (!user && !isLoading && !isSubscriptionLoading && !products && isProductLoading)
    return <ErrorContent />

  return (
    <>
      <div className="flex flex-col gap-8">
        <p className="text-3xl font-semibold">Zarządzaj subskrypcją</p>
        <div className="flex flex-col gap-8">
          {user ? <SubscriptionInfo user={user} subscription={subscription} /> : null}
          {user ? <AvailableMinutesInfo user={user} /> : null}
          {subscription ? null : <NoSubscriptionContent products={products} />}
        </div>
      </div>
    </>
  )
}

export default Subscription
