import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFetchMe } from '../../../hooks'
import { useAppSelector } from '../../../store/hooks'
import { useFetchSubscription } from '../../../hooks/request'

const TransactionSuccess = () => {
  const { token } = useAppSelector(state => state.auth)
  const { mutate: mutateUser } = useFetchMe(token)
  const { mutate: mutateSubscription } = useFetchSubscription(token)

  useEffect(() => {
    const refetchData = async () => {
      // await mutateUser()
      // await mutateSubscription()
      console.log('Refetching data')
    }
    refetchData()
  }, [])
  return (
    <>
      <h1>Tranzakcja zakończona pomyślnie</h1>
      <p>
        Minuty transkrypcji powinny zaktualizować się automatycznie. Jeżeli to nie nastąpi - wyloguj
        się i zaloguj ponownie.
      </p>
    </>
  )
}

export default TransactionSuccess
