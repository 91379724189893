import React from 'react'
import FooterLink from '../FooterLink'
import FooterNavigationLink from '../FooterNavigationLink'
import { AppPaths } from '../../../../enums'

const FooterContent = () => {
  return (
    <div className="hidden md:flex flex-row justify-around px-32">
      <div>
        <img src={require('../../../../assets/landing/logo.png')} alt="logo" className="w-24" />
      </div>
      <div>
        {/*<FooterLink title="Usługi" href="#" />*/}
        {/*<FooterLink title="FAQ" href="#" />*/}
        {/*<FooterLink title="Cennik" href="#" />*/}
        {/*<FooterLink title="Pomoc" href="#" />*/}
      </div>
      <div className="flex flex-col">
        <FooterNavigationLink title="Zasady i warunki" to={AppPaths.TERMS} />
        <FooterNavigationLink title="Polityka Prywatności" to={AppPaths.POLICY} />
      </div>
      <div id="contact">
        <FooterLink title="kontakt@myscribe.pl" href="mailto:kontakt@myscribe.pl" />
        <FooterLink title="518 735 682" href="tel:518735682" />
        <div className="flex flex-row gap-2 items-center min-w-[150px]">
          <a
            target="_blank"
            href="https://www.facebook.com/share/k7KyRSMsnbZ4x62U/?mibextid=LQQJ4d"
          >
            <img
              src={require('../../../../assets/landing/facebook.png')}
              alt="facebook"
              width={48}
            />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/myscribepl/">
            <img src={require('../../../../assets/landing/linkedin.png')} alt="linkedin" />
          </a>
          <a target="_blank" href="https://x.com/myscribe_?s=21&t=gJDhriTxm0btkWwk5Xrs5w">
            <img src={require('../../../../assets/landing/x.png')} alt="x" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterContent
