import useSWR from "swr";
import { ApiEndpoints } from "../../enums";
import { TranscriptSentence } from "assemblyai";
import { axiosFetcher } from "helpers";
import { AxiosError } from "axios";

export const useFetchAssemblyaiSentences = (
  id: string,
  token: string | null,
  shouldRefresh: boolean
) => {
  const { data, error, isLoading } = useSWR<TranscriptSentence[], AxiosError>(
    `${ApiEndpoints.ASSEMBLY_AI_TRANSCRIPTION}/${id}`,
    axiosFetcher(token),
    {
      refreshInterval: shouldRefresh ? 10000 : 0,
    }
  );

  return { assemblyaiSentences: data, error, isLoading };
};
