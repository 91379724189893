import { ActivateRequest } from '../../../types'
import { axiosClient } from '../../../helpers'
import { ApiEndpoints } from '../../../enums'

export const useActivateUser = () => {
  const activateUser = async ({ token }: ActivateRequest) => {
    try {
      const requestBody: ActivateRequest = { token }
      const response = await axiosClient(null).post(ApiEndpoints.ACTIVATE, requestBody)
      return response.data as string
    } catch (error) {
      throw new Error('Activate error')
    }
  }

  return { activateUser }
}
