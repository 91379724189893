import { ApiEndpoints } from "enums";
import useSWR from "swr";
import { User } from "types/user.types";
import { axiosFetcher } from "helpers";
import { AxiosError } from "axios";

export const useFetchMe = (token: string | null) => {
  const { data, error, isLoading,mutate } = useSWR<User, AxiosError>(
    ApiEndpoints.ME,
    axiosFetcher(token)
  );

  return { user: data, error, isLoading,mutate };
};
