import { AppPaths } from "../enums/appPaths";
import { useDispatch } from "react-redux";
import { clearToken } from "../store/auth/authSlice";
import { useNavigate } from "react-router-dom";

export const useHandleUnauthError = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearSessionStorageTokenAndRedirectToLogin = () => {
    dispatch(clearToken());
    navigate(AppPaths.LOGIN);
  };

  return { clearSessionStorageTokenAndRedirectToLogin };
};
