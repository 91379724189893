import React from 'react'
// import { LandingButton } from "@/app/(landing)/components";
import { HowWorksRoute, HowWorksVideo } from '..'
import LandingButton from '../LandingButton'
import { useNavigate } from 'react-router-dom'
import { AppPaths } from '../../../enums'

const HowWorksSection = () => {
  const navigate = useNavigate()
  const handlePress = () => {
    navigate(AppPaths.REGISTER)
  }
  return (
    <div className="mt-40 w-full">
      <p className="text-primaryBlue text-4xl text-center font-semibold mb-20">Jak to działa?</p>
      <HowWorksRoute />
      <HowWorksVideo />
      <div className="flex justify-center items-center mt-24">
        <LandingButton text="Sprawdź za darmo" type="primary-red" onClick={handlePress} />
      </div>
    </div>
  )
}

export default HowWorksSection
