import React from 'react'
import {
  HeaderSection,
  HowWorksSection,
  PriceSection,
  PriceTable,
  WhyUsSection,
} from '../../components/landing'
import { MobileSoon } from '../../components/common'

const Landing = () => {
  return (
    <>
      <div className="md:hidden">
        <MobileSoon />
      </div>
      <div className="hidden md:block max-w-[1100px] mx-auto ">
        <HeaderSection />
        <WhyUsSection />
        {/*<OurToolsSection />*/}
        <HowWorksSection />
        <PriceSection />
        {/*<PriceTable />*/}
      </div>
    </>
  )
}

export default Landing
