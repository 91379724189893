import React, { FC } from 'react'

interface Props {
  title: string
  onClick: () => void
}

const PrimaryButton: FC<Props> = ({ onClick, title }) => {
  return (
    <button
      className="bg-primaryBlue px-6 h-10 rounded-lg text-white border-transparent hover:text-primaryBlue hover:bg-transparent transition duration-300 hover:border-primaryBlue border-2"
      onClick={onClick}
    >
      {title}
    </button>
  )
}

export default PrimaryButton
