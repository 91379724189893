import { useEffect } from 'react'
import { LoginForm } from '../components/Login'
import { useDispatch } from 'react-redux'
import { clearToken } from 'store/auth/authSlice'
import { useNavigate } from 'react-router-dom'
import { AppPaths } from '../enums'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleRegisterClick = () => {
    navigate(AppPaths.REGISTER)
  }

  useEffect(() => {
    dispatch(clearToken())
  }, [])

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex justify-center items-center flex-col w-[750px] shadow-default rounded-3xl bg-white px-12 pt-28 pb-20">
        <p className="mb-20 text-4xl text-center font-semibold">Zaloguj się</p>
        <LoginForm />
        <p className="text-black mt-20">
          Nie masz konta?{' '}
          <span className="font-semibold cursor-pointer" onClick={handleRegisterClick}>
            Zarejestruj się.
          </span>
        </p>
      </div>
    </div>
  )
}

export default Login
