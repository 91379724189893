import { ApiEndpoints } from "enums";
import { axiosClient } from "helpers";

export const useLogoutUser = () => {
  const logoutUser = async (token: string | null) => {
    try {
      const response = await axiosClient(token).post(ApiEndpoints.LOGOUT);
      return response.data;
    } catch (error) {
      throw new Error("Login error");
    }
  };

  return { logoutUser };
};
