import { Empty } from 'antd'
import { TranscriptionTile } from 'components/Transcriptions'
import { ContentLoader, ErrorContent } from 'components/common'
import { AppPaths } from 'enums'
import { useFetchTranscriptions } from 'hooks'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

const Transcriptions = () => {
  const { token } = useAppSelector(state => state.auth)
  const { transcriptions, isLoading, error } = useFetchTranscriptions(token)

  if (isLoading) return <ContentLoader />

  if (!transcriptions?.length && !error)
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Brak transkrypcji. Stwórz swoją pierwszą transkrypcję klikając ''Stwórz transkrypcję'' w lewym górnym rogu."
      />
    )

  //TODO - add error handling
  if (error) {
    if (error.response?.status === 403) {
      return <ErrorContent />
    }
    return <div>Błąd przy wczytywaniu transkrypcji</div>
  }

  return (
    <div className="flex flex-col gap-6 py-8">
      {transcriptions?.map(transcription => {
        return <TranscriptionTile transcription={transcription} />
      })}
    </div>
  )
}

export default Transcriptions
