import React from 'react'
import { Button, Layout } from 'antd'
import { NavLink, useNavigate } from 'react-router-dom'
import { AppPaths } from '../../../enums'
import classNames from 'classnames'
import { useLogoutUser } from '../../../hooks'
import { clearToken } from '../../../store/auth/authSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../store/hooks'

const NavBar = () => {
  const { logoutUser } = useLogoutUser()
  const dispatch = useDispatch()
  const { token } = useAppSelector(state => state.auth)

  const { Header } = Layout
  const navigate = useNavigate()

  const handleNavigateToProfile = () => {
    navigate(AppPaths.PROFILE)
  }

  const handleLogout = async () => {
    await logoutUser(token)
    dispatch(clearToken())
    navigate(AppPaths.LOGIN)
  }

  return (
    <Header
      style={{
        width: '100%',
        height: '80px',
        backgroundColor: '#FFFFFF',
      }}
    >
      <div className="flex flex-row items-center justify-center h-full">
        <NavLink to={AppPaths.LANDING} className="mr-20">
          <img
            src={require('../../../assets/landing/logo.png')}
            width={64}
            height={32}
            alt="logo"
          />
        </NavLink>
        <div className="flex flex-row items-center gap-x-12">
          <NavLink
            className="text-blackText text-sm tracking-wider hover:text-primaryRed"
            to={AppPaths.LANDING}
          >
            Home
          </NavLink>
          <NavLink
            className="text-blackText text-sm tracking-wider hover:text-primaryRed"
            to={AppPaths.TRANSCRIPTIONS}
          >
            Transkrypcje
          </NavLink>
          <NavLink
            className="text-blackText text-sm tracking-wider hover:text-primaryRed"
            to={AppPaths.SUBSCRIPTION}
          >
            Subskrypcja
          </NavLink>
          <NavLink
            className="text-blackText text-sm tracking-wider hover:text-primaryRed"
            to={AppPaths.INVOICES}
          >
            Faktury
          </NavLink>
        </div>
        <div className="ml-auto h-full flex justify-center items-center gap-8">
          <Button
            onClick={handleNavigateToProfile}
            className="w-32 bg-white text-blackText border-1 border-blackText border hover:text-primaryRed"
          >
            Moje konto
          </Button>
          <Button className="w-32" onClick={handleLogout}>
            Wyloguj
          </Button>
        </div>
      </div>
    </Header>
  )
}

export default NavBar
