import axios from "axios";

export const axiosClient = (token: string | null) => {
  const authorizationHeader = token ? `Bearer ${token}` : "";
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      Authorization: authorizationHeader,
    },
  });

  return axiosClient;
};
