import React from 'react'
import { Button, Table, TableColumnsType, Tag } from 'antd'
import { Invoice } from '../types'
import { useAppSelector } from '../store/hooks'
import { useFetchInvoices } from '../hooks/request'

const Invoices = () => {
  const { token } = useAppSelector(state => state.auth)
  const { invoices, isLoading, error } = useFetchInvoices(token)

  const filteredInvoices = invoices ? invoices.filter(invoice => invoice.status !== 'draft') : []

  if (error) {
    return null
  }

  const columns: TableColumnsType<Invoice> = [
    { title: 'Produkt', dataIndex: 'productName', width: '20%' },
    { title: 'Data wystawienia', dataIndex: 'dateString', width: '20%' },
    { title: 'Kwota', dataIndex: 'priceString', width: '20%' },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      render: status => {
        let text = ''
        let color
        if (status === 'paid') {
          text = 'Opłacona'
          color = 'green'
        }
        if (status === 'open') {
          text = 'Do opłacenia'
          color = 'yellow'
        }
        if (status === 'void') {
          text = 'Anulowana'
          color = 'orange'
        }
        if (status === 'uncollectible') {
          text = 'Błąd płatności'
          color = 'red'
        }
        return <Tag color={color}>{text}</Tag>
      },
    },
    {
      title: '',
      dataIndex: 'pdfUrl',
      width: '10%',
      fixed: 'right',
      align: 'right',
      render: url => (
        <Button disabled={!url} download="invoice.pdf" href={url}>
          Pobierz fakturę
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: 'paymentUrl',
      width: '10%',
      fixed: 'right',
      align: 'right',
      render: paymentUrl => (
        <Button disabled={!paymentUrl} href={paymentUrl}>
          Opłać fakturę
        </Button>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={filteredInvoices}
      className="rounded-full"
      loading={isLoading}
    />
  )
}

export default Invoices
