import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'
export const getChangePasswordFormRules = () => {
  const password: Rule[] = [
    {
      min: 8,
      pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,
      required: true,
      message:
        'Hasło musi zawierać co najmniej 8 znaków, jedną dużą literę, jedną cyfrę oraz jeden znak specjalny',
    },
  ]

  const repeatPassword: Rule[] = [
    {
      required: true,
      message: 'Pole wymagane',
    },
    ({ getFieldValue }: { getFieldValue: (name: NamePath) => any }) => ({
      validator(_: any, value: string) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve()
        }
        return Promise.reject('Hasła nie są takie same')
      },
    }),
  ]

  return { password, repeatPassword }
}
