import React from 'react'
import { WhyUsFeature } from '..'

const WhyUsSection = () => {
  return (
    <div className="w-full">
      <p className="text-center font-semibold text-primaryBlue text-4xl mb-14">Dlaczego my?</p>
      <div className="flex flex-wrap flex-row gap-x-[10%] gap-y-12">
        <WhyUsFeature
          description="Zapomnij o poprawianiu błędów i literówek
w swoich tekstach."
          src={require('../../../assets/landing/features/accuracy3.png')}
          title="99,8% dokładności"
        />
        {/*<WhyUsFeature*/}
        {/*  description="Edytuj swoje pliki w intuicyjnym edytorze sekundę po otrzymaniu tekstu"*/}
        {/*  src={require('../../../assets/landing/features/simple.png')}*/}
        {/*  title="Prosty i szybki w użyciu"*/}
        {/*/>*/}
        <WhyUsFeature
          description="Perfekcyjna transkrypcja dostarczona
          w kilka sekund."
          src={require('../../../assets/landing/features/fast.png')}
          title="Zdumiewająca prędkość"
        />
        <WhyUsFeature
          description="Pobierz transkrypcje w formatach .srt oraz .txt"
          src={require('../../../assets/landing/features/export.png')}
          title="Wiele opcji eksportu"
        />
      </div>
    </div>
  )
}

export default WhyUsSection
