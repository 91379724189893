// import Link from "next/link";
import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  href: string
  children: ReactNode
}

const CustomLink: FC<Props> = ({ href, children }) => {
  return (
    //TODO - link
    <Link to={href} className="text-primaryBlue hover:text-primaryRed text-sm lg:text-xl font-bold">
      {children}
    </Link>
  )
}

export default CustomLink
