import { Modal } from 'antd'
import { ContentLoader } from 'components/common'
import React from 'react'

const TranscriptionProcessingLoader = () => {
  return (
    <>
      <p className="text-2xl mb-4">Trwa generowanie transkrypcji</p>
      <p className="mb-4">
        Transkrypcja jest generowana. Moze to zająć od kilku sekund do kilku minut - w zależności od
        rozmiaru pliku audio.
      </p>
      <p className="mb-8">
        Strona powinna odświeżyć się automatycznie, jezeli tego nie zrobi, poczekaj chwilę i
        odśwież.
      </p>
      <div className="mb-4">
        <ContentLoader />
      </div>
    </>
  )
}

export default TranscriptionProcessingLoader
