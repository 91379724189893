import { ContentLoader } from 'components/common'
import { LayoutSiderTranscriptionButton } from '..'
import { useFetchTranscriptions } from 'hooks'
import { useAppSelector } from 'store/hooks'
import { useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'

const TranscriptionsScrollView = () => {
  const { token } = useAppSelector(state => state.auth)
  const { transcriptions, isLoading, error } = useFetchTranscriptions(token)

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-[20%]">
        <ContentLoader />
      </div>
    )
  }

  if (error) {
    return <p>błąd przy wczytywaniu transkrypcji</p>
  }

  return (
    <>
      <div className="max-h-[50vh] overflow-scroll">
        {!isLoading ? (
          transcriptions?.map(transcription => {
            return (
              <>
                <LayoutSiderTranscriptionButton
                  key={transcription.id}
                  text={transcription.transcriptionName}
                  id={transcription.id}
                />
              </>
            )
          })
        ) : (
          <ContentLoader />
        )}
      </div>
    </>
  )
}

export default TranscriptionsScrollView
