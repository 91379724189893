import { Form, Input, Modal, notification } from 'antd'
import { useState } from 'react'
import { RegisterFormValues, RegisterRequest } from '../../../types'
import { getRegisterFormRules } from '../../../helpers'
import { useRegisterUser } from '../../../hooks'

const RegisterForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [toast, contextHolder] = notification.useNotification()
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const { registerUser } = useRegisterUser()

  const { name, repeatPassword, password, email } = getRegisterFormRules()
  const onFinish = async (values: RegisterFormValues) => {
    setIsLoading(true)
    try {
      await registerUser(values)
      toast.success({ message: 'Rejestracja powiodła się' })
      form.resetFields()
      setVisible(true)
    } catch {
      toast.error({ message: 'Rejestracja nie powiodła się' })
    }
    setIsLoading(false)
  }

  return (
    <>
      {contextHolder}
      <Modal
        footer={null}
        closable={false}
        open={visible}
        cancelText="Anuluj"
        okText="Zaloguj się"
        okButtonProps={{ className: 'bg-primaryBlue' }}
        centered
      >
        <p className="text-4xl text-primaryBlue mb-4">Sukces!</p>
        <p className="text-lg">
          Na podany adres E-mail została wysłana wiadomość. Postępuj zgodnie z instrukcjami
          wysłanymi na Twój adres E-mail aby aktywować konto.
        </p>
      </Modal>
      <Form form={form} onFinish={onFinish} className="w-full">
        <div className="flex flex-row justify-between gap-10">
          <div className="flex-1">
            <p className="mb-2">Imię</p>
            <Form.Item<RegisterFormValues> name="firstName" rules={name}>
              <Input
                className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
                placeholder={'Twóje imię'}
                data-testid="email"
              />
            </Form.Item>
          </div>
          <div className="flex-1">
            <p className="mb-2">Nazwisko</p>
            <Form.Item<RegisterFormValues> name="lastName" rules={name}>
              <Input
                className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
                placeholder={'Twoje nazwisko'}
                data-testid="email"
              />
            </Form.Item>
          </div>
        </div>
        <p className="mb-2">Adres e-mail</p>
        <Form.Item<RegisterFormValues> name="email" rules={email}>
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Twój adres e-mail'}
            data-testid="email"
          />
        </Form.Item>
        <p className="mb-2">Hasło</p>
        <Form.Item<RegisterFormValues> name="password" rules={password}>
          <Input.Password
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            visibilityToggle={false}
            placeholder={'Twoje hasło'}
            data-testid="password"
          />
        </Form.Item>
        <p className="mb-2">Powtórz hasło</p>
        <Form.Item<RegisterFormValues>
          name="repeatPassword"
          rules={repeatPassword}
          dependencies={['password']}
        >
          <Input.Password
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            visibilityToggle={false}
            placeholder={'Twoje hasło'}
            data-testid="password"
          />
        </Form.Item>
        <button
          disabled={isLoading}
          className="bg-primaryBlue border-2 border-primaryBlue py-3 px-10 font-bold rounded-3xl box-border w-full text-white mt-6 hover:bg-transparent hover:text-primaryBlue transition duration-300"
        >
          Zarejestruj się
        </button>
      </Form>
    </>
  )
}

export default RegisterForm
