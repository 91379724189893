import { CheckoutRequest, LoginRequest, LoginResponse } from '../../types'
import { axiosClient } from '../../helpers'
import { ApiEndpoints } from '../../enums'

export const useCreateCheckoutSession = (token: string | null) => {
  const createCheckoutSession = async ({ priceId }: CheckoutRequest) => {
    try {
      const requestBody: CheckoutRequest = { priceId }
      const response = await axiosClient(token).post(ApiEndpoints.CHECKOUT_SESSION, requestBody)
      return response.data as string
    } catch (error) {
      throw new Error('Checkout error')
    }
  }

  return { createCheckoutSession }
}
