import React from 'react'
import LandingButton from '../LandingButton'
import { AppPaths } from '../../../enums'
import { useNavigate } from 'react-router-dom'

const HeaderSection = () => {
  const navigate = useNavigate()
  const handleButtonClick = () => {
    navigate(AppPaths.REGISTER)
  }

  return (
    <div className="flex flex-row mt-32 items-center justify-between mb-40">
      <div className="w-1/2">
        <div className="text-5xl xl:text-7xl text-primaryBlue flex flex-col mb-8 font-semibold">
          <span>Najskuteczniejsza</span>
          <span>transkrypcja</span>
          <span>na rynku.</span>
        </div>
        <p className="text-xl lg:text-2xl font-light mb-8 text-primaryBlue">
          Zamień swoje pliki audio na tekst lub napisy w ciągu kilku sekund.
        </p>
        <LandingButton text="Sprawdź za darmo" type="primary-red" onClick={handleButtonClick} />
      </div>
      <div className="w-1/2 flex justify-center items-center">
        <img
          src={require('../../../assets/landing/header-image.png')}
          alt="Logo"
          width={350}
          height={425}
        />
      </div>
    </div>
  )
}

export default HeaderSection
