import { AppPaths } from "enums";
import { FC, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";

interface Props {
  children: ReactNode;
}

const AuthLayout: FC<Props> = ({ children }) => {
  const { token } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(AppPaths.LOGIN);
    }
  }, []);

  return <>{children}</>;
};

export default AuthLayout;
