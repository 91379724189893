import React from 'react'

const TermsContent = () => {
  return (
    <div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Usługa</h1>
        <p className="mb-2">
          Automatyczna transkrypcja plików audio przesłanych za pośrednictwem naszych stron
          internetowych
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Dostęp</h1>
        <p className="mb-2">
          Dostęp i korzystanie ze strony internetowej oraz Usług oferowanych na stronie
          internetowej, w tym między innymi Usług, procesu automatycznej transkrypcji, pobierania
          informacji o produktach i często zadawanych pytań, są zapewnione i dozwolone na zasadzie
          tymczasowej i podlegają niniejszym Warunkom. Szczególne warunki mogą mieć zastosowanie do
          określonych treści, produktów, materiałów, usług lub informacji zawartych na tej stronie
          internetowej lub dostępnych za jej pośrednictwem lub transakcji zawieranych za
          pośrednictwem tej witryny. Takie szczególne warunki mogą stanowić uzupełnienie Warunków
          lub, jeśli są niezgodne z niniejszymi Warunkami, tylko w zakresie, w jakim treść lub
          intencja takich szczególnych warunków jest niezgodna z niniejszymi Warunkami, takie
          szczególne warunki zastąpią niniejsze Warunki.
        </p>
        <p className="mb-2">
          FLC Studio Dariusz Konsek nie gwarantuje dostępności strony internetowej ani żadnej z
          oferowanych na niej usług.
        </p>
        <p className="mb-2">
          FLC Studio Dariusz Konsek zastrzega sobie prawo do poprawiania, zmieniania, modyfikowania,
          usuwania lub zawieszania zawartości dowolnej części witryny internetowej i/lub wszelkich
          usług, które świadczy na stronie internetowej bez uprzedzenia, w dowolnym momencie i
          według własnego uznania.
        </p>
        <p className="mb-2">
          FLC Studio Dariusz Konsek nie ponosi żadnej odpowiedzialności za jakiekolwiek przerwy lub
          zaprzestanie działania którejkolwiek lub wszystkich funkcji strony internetowej lub Usługi
          z jakiegokolwiek powodu innego niż uzgodnione w innym miejscu bezpośrednio z
          Użytkownikiem.
        </p>
        <p className="mb-2">
          FLC Studio Dariusz Konsek nie jest zobowiązany do aktualizowania jakichkolwiek informacji
          zawartych na stronie internetowej. FLC Studio Dariusz Konsek zastrzega sobie prawo, według
          własnego uznania, do zablokowania dostępu do całości lub części strony internetowej lub
          Usługi za powiadomieniem lub bez.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Informacja o prawach autorskich</h1>
        <p className="mb-2">
          Zawartość i Serwis wymienionych stron internetowych (https://myscribe.pl/) są własnością
          MyScribe lub są używane na podstawie licencji od zewnętrznych właścicieli praw autorskich
          i są chronione prawem autorskim i wszelkimi innymi prawami własności intelektualnej. O ile
          nie postanowiono inaczej, treści publikowane na tej stronie internetowej mogą być
          powielane, przesyłane, retransmitowane, publikowane lub rozpowszechniane w niezmienionej
          formie wyłącznie do osobistego, niekomercyjnego użytku lub za wyraźną pisemną zgodą
          MyScribe. Z zastrzeżeniem poniższej licencji, wszelkie prawa, które nie zostały wyraźnie
          udzielone w niniejszym dokumencie, są zastrzeżone. Wszystkie informacje o prawach
          autorskich i innych prawach własności zostaną zachowane na wszystkich reprodukcjach.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Licencja na korzystanie ze strony internetowej</h1>
        <p className="mb-2">
          ZUżytkownik może korzystać ze strony internetowej oraz zawartych na niej informacji i
          materiałów w celu przesyłania plików dźwiękowych, ich edycji, udostępniania i
          eksportowania na zasadzie niewyłączności. Z wyjątkiem plików audio, które masz osobiście
          na swoim koncie MyScribe:
        </p>
        <p className="mb-2">
          Nie wolno ponownie publikować materiałów z tej witryny (w tym ponownie publikować na innej
          stronie internetowej) ani powielać ani przechowywać materiałów z tej witryny w żadnym
          publicznym lub prywatnym elektronicznym systemie wyszukiwania;
        </p>
        <p className="mb-2">
          Nie wolno powielać, kopiować, sprzedawać, odsprzedawać, odwiedzać ani w inny sposób
          wykorzystywać naszej strony internetowej lub materiałów na naszej stronie internetowej w
          celach komercyjnych bez naszej wyraźnej pisemnej zgody;
        </p>
        <p className="mb-2">
          Nie wolno używać żadnych zdjęć, grafik, sekwencji wideo lub audio oddzielnie od
          towarzyszącego im tekstu.
        </p>
        <p className="mb-2">
          Nie wolno zmieniać, dekompilować, edytować ani w inny sposób modyfikować żadnych
          materiałów na stronie internetowej.
        </p>
        <p className="mb-2">
          Udzielamy licencji na korzystanie z wszelkich aplikacji zawartych na stronie internetowej
          i wyłącznie w celach udostępnionych na stronie internetowej. Użytkownikowi nie wolno
          tłumaczyć, adaptować, zmieniać, modyfikować, rozpowszechniać, dekompilować ani odtwarzać
          kodu źródłowego tego oprogramowania w jakimkolwiek celu, tworzyć na jego podstawie
          jakichkolwiek dzieł pochodnych, zezwalać na łączenie go z jakimkolwiek innym
          oprogramowaniem, zapewniać do niego dostęp lub używać go do świadczenia usług osobom
          trzecim, z wyjątkiem zakresu, w jakim jest to wymagane przez obowiązujące prawo.
          Użytkownik nie może korzystać z oprogramowania w sposób inny niż określony w niniejszych
          warunkach bez naszej uprzedniej pisemnej zgody. Korzystanie z wszelkich usług,
          oprogramowania, informacji i materiałów dostępnych na stronie internetowej może podlegać
          dodatkowym warunkom, o których MyScribe powiadomi użytkownika w momencie pobierania lub
          uzyskiwania dostępu. Pobranie lub korzystanie z takich usług, informacji i materiałów
          będzie oznaczać akceptację tych dodatkowych warunków. Jeśli nie zgadzasz się z tymi
          dodatkowymi warunkami, nie korzystaj z Usług.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Ograniczenia odpowiedzialności</h1>
        <p className="mb-2">
          Informacje zawarte na tej stronie internetowej są udostępniane bezpłatnie, a użytkownik
          przyjmuje do wiadomości, że nieuzasadnione byłoby pociąganie nas do odpowiedzialności w
          odniesieniu do tej strony internetowej i informacji na niej zawartych. Chociaż dołożono
          wszelkich starań, aby zapewnić dokładność informacji zawartych na tej stronie
          internetowej, FLC Studio Dariusz Konsek nie ponosi z tego tytułu żadnej odpowiedzialności.
          Cała zawartość jest dostarczana "w stanie, w jakim się znajduje" i "w miarę dostępności".
          FLC Studio Dariusz Konsek niniejszym wyraźnie zrzeka się wszelkich oświadczeń lub
          gwarancji jakiegokolwiek rodzaju, wyraźnych lub dorozumianych, w tym między innymi
          gwarancji przydatności handlowej, przydatności do określonego celu, nienaruszalności lub
          co do działania tej witryny internetowej lub jej zawartości. FLC Studio Dariusz Konsek nie
          gwarantuje ani nie składa żadnych oświadczeń co do bezpieczeństwa tej strony internetowej.
          Użytkownik przyjmuje do wiadomości, że wszelkie przesyłane informacje mogą zostać
          przechwycone. FLC Studio Dariusz Konsek nie gwarantuje, że strona internetowa lub serwery,
          które ją udostępniają, lub komunikacja elektroniczna wysyłana przez FLC Studio Dariusz
          Konsek są wolne od wirusów lub innych szkodliwych elementów.
        </p>
        <p className="mb-2">
          W żadnym wypadku FLC Studio Dariusz Konsek nie ponosi odpowiedzialności za jakiekolwiek
          bezpośrednie, pośrednie, wtórne, karne, specjalne lub przypadkowe szkody (w tym, bez
          ograniczeń, szkody z tytułu utraty działalności, kontraktu, przychodów, danych, informacji
          lub przerw w działalności) wynikające z lub w związku z korzystaniem lub niemożnością
          korzystania z tej witryny internetowej lub jej treści, nawet jeśli firma FLC Studio
          Dariusz Konsek została poinformowana o możliwości wystąpienia takich szkód. Wszelkie
          działania wniesione przeciwko firmie FLC Studio Dariusz Konsek dotyczące lub w związku z
          niniejszą witryną internetową muszą zostać wszczęte i zgłoszone firmie FLC Studio Dariusz
          Konsek na piśmie w ciągu jednego (1) roku od daty powstania podstawy powództwa. Żadne z
          postanowień niniejszego wyłączenia odpowiedzialności nie wyłącza ani nie ogranicza naszej
          odpowiedzialności za oszustwo, śmierć lub obrażenia ciała spowodowane naszym zaniedbaniem
          ani żadnej innej odpowiedzialności, której nie można wyłączyć ani ograniczyć na mocy
          obowiązującego prawa.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Linki do tej strony internetowej</h1>
        <p className="mb-2">
          Nie możesz tworzyć linków do żadnej strony tej witryny bez naszej uprzedniej pisemnej
          zgody. Jeśli utworzysz link do strony tej witryny, robisz to na własne ryzyko, a
          wyłączenia i ograniczenia określone w niniejszych Warunkach będą miały zastosowanie do
          Użytkownika korzystającego z tej witryny poprzez zamieszczenie do niej linku.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Linki do innych stron</h1>
        <p className="mb-2">
          Od czasu do czasu strona internetowa może również zawierać linki do innych stron
          internetowych osób trzecich, które nie są kontrolowane przez FLC Studio Dariusz Konsek.
          Linki te mają na celu dostarczenie dalszych informacji i nie mają na celu oznaczania, że
          FLC Studio Dariusz Konsek popiera takie strony internetowe i/lub ich zawartość lub jest w
          jakikolwiek sposób powiązany ze stroną internetową, do której prowadzą linki. FLC Studio
          Dariusz Konsek nie ponosi odpowiedzialności za treść strony internetowej, do której
          prowadzi link. Twoim obowiązkiem jest sprawdzenie warunków korzystania z każdej innej
          strony internetowej, którą możesz odwiedzić. Dalsze linki udostępniane przez stronę
          internetową lub zasoby nie są badane, weryfikowane, monitorowane ani zatwierdzane przez
          FLC Studio Dariusz Konsek i są wykorzystywane na własne ryzyko.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Złożenia</h1>
        <p className="mb-2">
          Użytkownik gwarantuje, że wszelkie informacje, obrazy, komentarze lub dane dowolnego
          rodzaju, które przesyła do witryny, pocztą elektroniczną lub w inny sposób, nie są
          zniesławiające ani obraźliwe, nieprawdziwe, obraźliwe na tle rasowym lub podżegające do
          nienawiści rasowej lub w inny sposób naruszające prawo jednostki do prywatności lub praw
          człowieka lub podlegające zaskarżeniu w jakiejkolwiek jurysdykcji. W przypadku, gdy
          użytkownik prześle takie materiały na stronę internetową, firma FLC Studio Dariusz Konsek
          zastrzega sobie prawo do ich usunięcia bez odniesienia do użytkownika i współpracy z
          wszelkimi dochodzeniami prowadzonymi przez władze lub nakazem sądowym w związku z tym,
          wszelkie konsekwencje, które mogą bezpośrednio lub pośrednio nastąpić, będą całkowicie
          ponoszone przez użytkownika, a nie za odpowiedzialność firmy FLC Studio Dariusz Konsek.
          Użytkownik zgadza się zabezpieczyć i zwolnić firmę FLC Studio Dariusz Konsek z
          odpowiedzialności za wszelkie działania lub konsekwencje, które mogą wystąpić w takich
          okolicznościach, w tym wszelkie roszczenia osób trzecich. Ponadto użytkownik gwarantuje,
          że wszelkie dane osób trzecich przekazane przez użytkownika, czy to poprzez wysyłanie
          wiadomości na nasze adresy e-mail, wypełnianie formularzy, korzystanie z dowolnej usługi
          czatu online lub w inny sposób, są dostarczane przez użytkownika za pełną świadomą zgodą
          odpowiedniej strony i są dokładne. FLC Studio Dariusz Konsek ma prawo ujawnić tożsamość
          użytkownika osobom trzecim, które twierdzą, że jakiekolwiek informacje lub materiały
          dostarczone, zamieszczone lub przesłane przez użytkownika na stronę internetową stanowią
          naruszenie ich praw własności intelektualnej lub prawa do prywatności bez ponoszenia
          odpowiedzialności wobec użytkownika.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Informacje poufne i ujawnianie informacji</h1>
        <p className="mb-2">
          W związku ze świadczeniem Usługi może być konieczne lub pożądane, aby użytkownik lub firma
          FLC Studio Dariusz Konsek ujawnili innym niepublicznym informacjom poufnym. Dla celów
          niniejszych Warunków "Informacje poufne" oznaczają wszelkie niepubliczne, poufne i
          zastrzeżone informacje dotyczące Stron, ich klientów i Usługi, które zostały lub zostaną
          ujawnione przez Stronę ustnie lub w formie pisemnej lub w innej formie materialnej. Strona
          otrzymująca niniejszym zobowiązuje się do zachowania w ścisłej tajemnicy i dołożenia
          wszelkich starań w celu zachowania w tajemnicy wszelkich Informacji Poufnych ujawnionych
          przez Stronę ujawniającą zgodnie z niniejszymi Warunkami i nie może ujawniać Informacji
          Poufnych bez wyraźnej, pisemnej uprzedniej zgody Strony ujawniającej, z następującymi
          wyjątkami:
        </p>
        <p className="mb-2">
          Informacje, które w momencie ujawnienia są publicznie dostępne lub następnie stają się
          publicznie dostępne poprzez publikację lub w inny sposób, inny niż naruszenie niniejszej
          Umowy przez Stronę otrzymującą.
        </p>
        <p className="mb-2">
          Informacje, które Strona otrzymująca może ustalić na podstawie uprzedniego zapisu, były
          jej już znane lub znajdowały się w jej posiadaniu w momencie ujawnienia i nie zostały
          uzyskane, bezpośrednio lub pośrednio, od Strony ujawniającej.
        </p>
        <p className="mb-2">
          Informacje, które Strona otrzymująca uzyskuje od osoby trzeciej; pod warunkiem jednak, że
          takie informacje nie zostały uzyskane przez wspomnianą stronę trzecią, bezpośrednio lub
          pośrednio, od Strony ujawniającej w ramach zobowiązania do zachowania poufności wobec
          Strony ujawniającej.
        </p>
        <p className="mb-2">
          Informacje, które Strona otrzymująca może ustalić, zostały samodzielnie opracowane przez
          jej pracowników lub kontrahentów, którzy nie mieli kontaktu z Informacją Poufną i nie byli
          świadomi jej treści.
        </p>
        <p className="mb-2">
          Strona otrzymująca może ujawnić Informacje Poufne, jeżeli zostanie do tego zmuszona przez
          sąd, organ administracyjny lub inny trybunał właściwej jurysdykcji, pod warunkiem jednak,
          że w takim przypadku Strona otrzymująca, niezwłocznie po otrzymaniu zawiadomienia, że
          ujawnienie może być wymagane, przekaże pisemne zawiadomienie faksem i pocztą nocną do
          Strony przekazującej, tak aby Strona przekazująca mogła ubiegać się o nakaz ochronny lub
          inny środek zaradczy od wspomnianego sądu lub trybunału. W każdym przypadku Strona
          otrzymująca ujawni tylko tę część Informacji Poufnych, która w opinii jej radcy prawnego
          jest prawnie zobowiązana do ujawnienia i dołoży należytych starań w celu zapewnienia, że
          wszelkie takie informacje ujawnione w ten sposób zostaną potraktowane jako poufne przez
          wspomniany sąd lub trybunał poprzez nakazy ochronne, dokumenty opatrzone pieczęcią i inne
          odpowiednie środki.
        </p>
        <p className="mb-2">
          Strona otrzymująca nie będzie wykorzystywać Informacji Poufnych w celach innych niż
          związane z Usługą lub w celu ulepszania lub rozwijania nowych usług. Strona otrzymująca
          ujawni Informacje poufne wyłącznie swoim dyrektorom, członkom zarządu, pracownikom,
          agentom, usługodawcom lub spółkom powiązanym, z których wszystkie będą związane warunkami
          nie mniej uciążliwymi niż niniejsze Warunki.
        </p>
        <p className="mb-2">
          Strona otrzymująca podejmie wszelkie uzasadnione kroki, w tym między innymi kroki podjęte
          w celu ochrony własnych informacji, danych lub innych dóbr materialnych lub
          niematerialnych, które uważa za zastrzeżone lub poufne, w celu zapewnienia, że Informacje
          poufne nie zostaną ujawnione lub powielone na użytek jakiejkolwiek osoby trzeciej, a także
          podejmie wszelkie uzasadnione kroki w celu uniemożliwienia ich dyrektorom, urzędnikom,
          pracownikom i agentom (w zależności od przypadku), którzy mają dostęp do Informacji
          poufnych, przed ujawnieniem lub nieuprawnionym wykorzystaniem jakichkolwiek Informacji
          poufnych lub przed popełnieniem jakichkolwiek działań lub zaniechań, które mogą skutkować
          naruszeniem niniejszych Warunków.
        </p>
        <p className="mb-2">
          Na pisemne żądanie Strony ujawniającej, Strona otrzymująca niezwłocznie zwróci Stronie
          ujawniającej wszystkie materiały i dokumenty, jak również wszelkie dane lub inne nośniki
          (w tym dane komputerowe i informacje elektroniczne) wraz z wszelkimi ich kopiami lub
          zniszczy wspomniane Informacje Poufne, a na żądanie Strony ujawniającej dostarczy
          certyfikat zniszczenia
        </p>
        <p className="mb-2">
          FLC Studio Dariusz Konsek przetwarza informacje o osobach odwiedzających stronę
          internetową oraz informacje otrzymane zgodnie z Oświadczeniem. Korzystając ze strony
          internetowej, wyrażasz zgodę na takie przetwarzanie i gwarantujesz, że wszystkie podane
          przez Ciebie dane użytkownika są dokładne.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Ograniczony dostęp</h1>
        <p className="mb-2">
          Dostęp do niektórych obszarów naszej strony internetowej jest ograniczony. Zastrzegamy
          sobie prawo do ograniczenia dostępu do niektórych obszarów naszej strony internetowej, a
          nawet do całej naszej strony internetowej, według naszego uznania. Jeśli udostępnimy Ci
          identyfikator użytkownika i hasło, aby umożliwić Ci dostęp do zastrzeżonych obszarów
          naszej witryny internetowej lub innych treści lub Usług, musisz upewnić się, że Twój
          identyfikator użytkownika i hasło są poufne. Użytkownik przyjmuje odpowiedzialność za
          wszystkie działania, które mają miejsce przy użyciu jego identyfikatora użytkownika lub
          hasła. Możemy wyłączyć Twój identyfikator użytkownika i hasło według własnego uznania,
          jeśli naruszysz którąkolwiek z zasad lub warunków regulujących korzystanie z naszej strony
          internetowej lub jakiekolwiek inne zobowiązania umowne, które jesteś nam winien.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Całość umowy</h1>
        <p className="mb-2">
          nformacje zawarte na niniejszej stronie stanowią całość umowy między użytkownikiem a nami
          w odniesieniu do korzystania z naszej strony internetowej i zastępują wszystkie poprzednie
          umowy dotyczące korzystania z tej witryny.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Prawo i jurysdykcja</h1>
        <p className="mb-2">
          Informacje zawarte na niniejszej stronie podlegają prawu polskiemu i będą interpretowane
          zgodnie z nim, a wszelkie spory związane z niniejszą informacją będą podlegać wyłącznej
          jurysdykcji sądów polskich
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Polityka zwrotów</h1>
        <p className="mb-2">
          W przypadkach, gdy nie jesteś zadowolony z jakości transkrypcji lub naszych usług,
          zwrócimy Ci pełną ostatnią opłatę abonamentową, jeśli powiadomisz nas o tym w ciągu (7)
          dni kalendarzowych od daty płatności. Każdy użytkownik może poprosić o zwrot pieniędzy
          tylko raz.
        </p>
      </div>
      <div className="mb-3">
        <h1 className="text-xl font-semibold">Kontakt z nami</h1>
        <p className="mb-2">
          Prosimy o kontakt z nami (kontakt@myscribe.pl) w jakiejkolwiek sprawie związanej z
          niniejszymi Warunkami lub Oświadczeniem.
        </p>
      </div>
    </div>
  )
}

export default TermsContent
