import useSWR from 'swr'
import { ApiEndpoints } from '../../enums'
import { axiosFetcher } from 'helpers'
import { AxiosError } from 'axios'
import { Product } from '../../types'

export const useFetchProducts = (token: string | null) => {
  const { data, error, isLoading, mutate } = useSWR<Product[], AxiosError>(
    `${ApiEndpoints.STRIPE}/products`,
    axiosFetcher(token),
  )

  return { products: data, error, isLoading, mutate }
}
