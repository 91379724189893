import { SubscriptionProductTile } from '../index'
import { useFetchProducts } from '../../../hooks'
import { useAppSelector } from '../../../store/hooks'
import { AdditionalProductInfo, Product } from '../../../types'
import { additionalProductInfo } from './aditionalProductInfo'
import { FC } from 'react'

interface Props {
  products?: Product[]
}
const NoSubscriptionContent: FC<Props> = ({ products }) => {
  const { token } = useAppSelector(state => state.auth)
  const sortedProducts = products?.sort((a, b) => a.price - b.price)

  return (
    <>
      <div className="flex flex-row gap-10">
        {sortedProducts?.map((product, index) => {
          return (
            <SubscriptionProductTile
              product={product}
              key={`product-tile-${product.productId}`}
              additionalProductInfo={additionalProductInfo[index]}
            />
          )
        })}
      </div>
    </>
  )
}

export default NoSubscriptionContent
