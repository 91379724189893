import { AppPaths } from 'enums'
import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  text: string
  id: string
}

const LayoutSiderTranscriptionButton: FC<Props> = ({ text, id }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const path = location.pathname
  const isActive = path.includes(id)
  const bgColor = isActive ? 'bg-background' : ''

  const handlePushToTranscriptionPage = () => {
    navigate(AppPaths.TRANSCRIPTIONS + '/' + id)
  }
  return (
    <p
      className={`${bgColor} text-blackText text-lg w-full hover:bg-background py-3 px-8 cursor-pointer transition duration-300`}
      onClick={handlePushToTranscriptionPage}
    >
      {text}
    </p>
  )
}

export default LayoutSiderTranscriptionButton
