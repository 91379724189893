import React, { FC } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { AppPaths } from '../../../../enums'

interface Props {
  to: AppPaths
  text: string
}
const ProfileNavigationTile: FC<Props> = ({ to, text }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const isActive = to === pathname
  const bgColor = isActive ? 'bg-background' : ''
  const handleNavigation = () => {
    navigate(to)
  }

  return (
    <button
      onClick={handleNavigation}
      className={`${bgColor} text-blackText text-lg w-full hover:bg-background py-3 px-8 cursor-pointer transition duration-300 tracking-wider`}
    >
      {text}
    </button>
  )
}

export default ProfileNavigationTile
