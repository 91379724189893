import { FC, ReactNode } from 'react'
import { SiderContent } from './components'
import { Layout } from 'antd'
import { NavBar } from '../components'

interface Props {
  children: ReactNode
}

const TranscriptionLayout: FC<Props> = ({ children }) => {
  const { Content, Footer, Header, Sider } = Layout

  return (
    <Layout>
      <NavBar />
      <Layout>
        <Sider
          width={'20%'}
          style={{
            height: '100vh',
            backgroundColor: '#FFFFFF',
            position: 'sticky',
            top: 0,
          }}
        >
          <SiderContent />
        </Sider>
        <Content style={{ minHeight: 'calc(100vh - 80px)', padding: 50 }}>{children}</Content>
      </Layout>
    </Layout>
  )
}

export default TranscriptionLayout
