import React, { FC } from 'react'
import { Form, Input, notification } from 'antd'
import { CompanyInformation, UserFormValues } from '../../../types'
import { User } from '../../../types/user.types'
import { getUserFormRules } from '../../../helpers'
import { useUpdateUserInformation } from '../../../hooks'
import { useAppSelector } from '../../../store/hooks'
import { NotificationInstance } from 'antd/lib/notification/interface'

interface Props {
  user: User
  toast: NotificationInstance
}

const UserForm: FC<Props> = ({ user, toast }) => {
  const { email, firstName, lastName, phoneNumber } = user
  const { token } = useAppSelector(state => state.auth)
  const { lastNameRules, emailRules, nameRules, phoneNumberRules } = getUserFormRules()
  const { updateUserInformation } = useUpdateUserInformation()

  const onFinish = async (loginData: UserFormValues) => {
    try {
      await updateUserInformation(token, loginData)
      toast.success({ message: 'Dane zostały zaktualizowane' })
    } catch {
      toast.error({ message: 'Dane nie zostały zaktualizowane' })
    }
  }

  return (
    <div className="flex flex-col gap-6 py-8 w-1/2">
      <p>Dane użytkownika</p>
      <Form onFinish={onFinish} className="w-full">
        <p className="mb-2">
          Adres e-mail <span className="text-red-500">*</span>
        </p>
        <Form.Item<UserFormValues> initialValue={email} name="email" rules={emailRules}>
          <Input
            disabled={true}
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Twój adres e-mail'}
            data-testid="email"
          />
        </Form.Item>
        <p className="mb-2">
          Imię <span className="text-red-500">*</span>
        </p>
        <Form.Item<UserFormValues> initialValue={firstName} name="firstName" rules={nameRules}>
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Imię'}
            data-testid="password"
          />
        </Form.Item>
        <p className="mb-2">
          Nazwisko <span className="text-red-500">*</span>
        </p>
        <Form.Item<UserFormValues> initialValue={lastName} name="lastName" rules={lastNameRules}>
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Nazwisko'}
            data-testid="password"
          />
        </Form.Item>
        <p className="mb-2">Numer telefonu</p>
        <Form.Item<UserFormValues>
          name="phoneNumber"
          rules={phoneNumberRules}
          initialValue={phoneNumber}
        >
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Numer telefonu'}
            data-testid="password"
          />
        </Form.Item>
        <button
          type="submit"
          className="bg-primaryBlue w-1/3 border-2 border-primaryBlue py-3 px-10 font-bold rounded-xl box-border text-white mt-6 hover:bg-transparent hover:text-primaryBlue transition duration-300"
        >
          Zapisz
        </button>
      </Form>
    </div>
  )
}

export default UserForm
