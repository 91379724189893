import useSWR from "swr";
import { Transcription } from "../../types";
import { ApiEndpoints } from "../../enums";
import { axiosFetcher } from "helpers";
import { useHandleUnauthError } from "hooks/useHandleUnauthError";
import { AxiosError } from "axios";

export const useFetchTranscriptions = (token: string | null) => {
  const { clearSessionStorageTokenAndRedirectToLogin } = useHandleUnauthError();
  const { data, error, isLoading ,mutate} = useSWR<Transcription[], AxiosError>(
    ApiEndpoints.TRANSCRIPTION,
    axiosFetcher(token)
  );

  return { transcriptions: data, error, isLoading ,mutate};
};
