import { ApiEndpoints } from '../../enums'
import { axiosClient } from 'helpers'
import { UpdateCompanyInformationRequest } from '../../types'

export const useUpdateCompanyInformation = () => {
  const updateCompanyInformation = async (
    token: string | null,
    requestBody: UpdateCompanyInformationRequest,
  ) => {
    try {
      const response = await axiosClient(token).put(
        ApiEndpoints.STRIPE + '/company-information',
        requestBody,
      )

      return response
    } catch (error) {
      throw new Error('Login error')
    }
  }

  return { updateCompanyInformation }
}
