export enum ErrorMessages {
  USER_NOT_FOUND = 'User not found',
  FILE_TOO_LARGE = 'File to large',
  FILE_TOO_LONG = 'File too long',
  TIME_LIMIT_EXCEEDED = 'Time limit exceeded',
  STRIPE_EXCEPTION = 'Stripe exception',
  USER_EXISTS = 'User already exists',
  USER_NOT_ACTIVATED = 'User not activated',
  TRANSCRIPTION_NOT_FOUND = 'Transcription not found',
  EMAIL_TOKEN_NOT_FOUND = 'Email token not found',
}
