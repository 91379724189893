import React, { useState } from 'react'
import { Form, Input } from 'antd'
import { ChangePasswordFormValues, ResetPasswordFormValues } from '../../../types'
import { getChangePasswordFormRules } from '../../../helpers'
import { useChangePassword } from '../../../hooks/request'
import { useSearchParams } from 'react-router-dom'

const ChangePasswordForm = () => {
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm()
  const { repeatPassword, password } = getChangePasswordFormRules()
  const { changePassword } = useChangePassword()
  const [loading, setLoading] = useState(false)

  const token = searchParams.get('token')

  const onFinish = async (values: ChangePasswordFormValues) => {
    if (token) {
      try {
        setLoading(true)
        await changePassword({ password: values.password, token })
      } catch {
        console.log('error')
      }
      setLoading(false)
    }
  }
  return (
    <>
      {/*{contextHolder}*/}
      {/*<Modal*/}
      {/*    open={visible}*/}
      {/*    cancelText="Anuluj"*/}
      {/*    okText="Zaloguj się"*/}
      {/*    okButtonProps={{ className: 'bg-primaryBlue' }}*/}
      {/*    centered*/}
      {/*    onOk={handleOk}*/}
      {/*>*/}
      {/*    <p className="text-4xl text-primaryBlue mb-4">Sukces!</p>*/}
      {/*    <p className="text-lg">Rejestracja przebiegłą pomyślnie. Zaloguj się!</p>*/}
      {/*</Modal>*/}
      <Form form={form} onFinish={onFinish} className="w-full">
        {/*<div className="flex flex-row justify-between gap-10">*/}
        {/*    <div className="flex-1">*/}
        {/*        <p className="mb-2">Imię</p>*/}
        {/*        <Form.Item<RegisterFormValues> name="firstName" rules={name}>*/}
        {/*            <Input*/}
        {/*                className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"*/}
        {/*                placeholder={'Twóje imię'}*/}
        {/*                data-testid="email"*/}
        {/*            />*/}
        {/*        </Form.Item>*/}
        {/*    </div>*/}
        {/*    <div className="flex-1">*/}
        {/*        <p className="mb-2">Nazwisko</p>*/}
        {/*        <Form.Item<RegisterFormValues> name="lastName" rules={name}>*/}
        {/*            <Input*/}
        {/*                className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"*/}
        {/*                placeholder={'Twoje nazwisko'}*/}
        {/*                data-testid="email"*/}
        {/*            />*/}
        {/*        </Form.Item>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<p className="mb-2">Adres e-mail</p>*/}
        {/*<Form.Item<RegisterFormValues> name="email" rules={email}>*/}
        {/*    <Input*/}
        {/*        className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"*/}
        {/*        placeholder={'Twój adres e-mail'}*/}
        {/*        data-testid="email"*/}
        {/*    />*/}
        {/*</Form.Item>*/}
        <p className="mb-2">Hasło</p>
        <Form.Item<ChangePasswordFormValues> name="password" rules={password}>
          <Input.Password
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            visibilityToggle={false}
            placeholder={'Twoje hasło'}
            data-testid="password"
          />
        </Form.Item>
        <p className="mb-2">Powtórz hasło</p>
        <Form.Item<ChangePasswordFormValues>
          name="repeatPassword"
          rules={repeatPassword}
          dependencies={['password']}
        >
          <Input.Password
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            visibilityToggle={false}
            placeholder={'Twoje hasło'}
            data-testid="password"
          />
        </Form.Item>
        <button
          disabled={loading}
          className="bg-primaryBlue border-2 border-primaryBlue py-3 px-10 font-bold rounded-3xl box-border w-full text-white mt-6 hover:bg-transparent hover:text-primaryBlue transition duration-300"
        >
          Zarejestruj się
        </button>
      </Form>
    </>
  )
}

export default ChangePasswordForm
