import React from 'react'
import { TransactionSuccess } from '../components/TransactionResult'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'antd'
import { AppPaths } from '../enums'

const TransactionResult = () => {
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')
  const navigate = useNavigate()

  const handleNavigateToProfile = () => {
    navigate(AppPaths.SUBSCRIPTION)
  }

  return <>{status === 'success' ? <TransactionSuccess /> : null}</>
}

export default TransactionResult
