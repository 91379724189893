import React, { FC } from 'react'

interface Props {
  title: string
  circleText: string
}

const HowWorksCircle: FC<Props> = ({ circleText, title }) => {
  return (
    <div className="flex flex-col justify-between items-center relative mb-10">
      <div className="w-28 h-28 rounded-full shadow-default flex justify-center items-center z-10 bg-primaryWhite mb-10">
        <p className="text-primaryBlue text-6xl font-semibold">{circleText}</p>
      </div>
      <p className="text-primaryBlue text-center text-xl lg:text-2xl absolute text-nowrap -bottom-10">
        {title}
      </p>
    </div>
  )
}

export default HowWorksCircle
