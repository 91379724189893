import { CheckoutRequest, LoginRequest, LoginResponse } from '../../types'
import { axiosClient } from '../../helpers'
import { ApiEndpoints } from '../../enums'

export const useCreatePortalSession = (token: string | null) => {
  const createPortalSession = async () => {
    try {
      const response = await axiosClient(token).post(ApiEndpoints.PORTAL_SESSION)
      return response.data as string
    } catch (error) {
      throw new Error('Portal error')
    }
  }

  return { createPortalSession }
}
