import React, { FC, ReactNode } from 'react'

interface Props {
  href: string
  children: ReactNode
}

const CustomInnerLink: FC<Props> = ({ children, href }) => {
  return (
    <a className="text-primaryBlue hover:text-primaryRed text-sm lg:text-xl font-bold" href={href}>
      {children}
    </a>
  )
}

export default CustomInnerLink
