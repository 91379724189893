import {
  AdditionalTimeCheckoutRequest,
  CheckoutRequest,
  LoginRequest,
  LoginResponse,
} from '../../types'
import { axiosClient } from '../../helpers'
import { ApiEndpoints } from '../../enums'

export const useCreateAdditionalTimeCheckoutSession = (token: string | null) => {
  const createAdditionalTimeCheckoutSession = async ({ amount }: AdditionalTimeCheckoutRequest) => {
    try {
      const requestBody: AdditionalTimeCheckoutRequest = { amount }
      const response = await axiosClient(token).post(
        ApiEndpoints.ADDITIONAL_TIME_CHECKOUT_SESSION,
        requestBody,
      )
      return response.data as string
    } catch (error) {
      throw new Error('Checkout error')
    }
  }

  return { createAdditionalTimeCheckoutSession }
}
