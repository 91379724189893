import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useActivateUser } from '../hooks/request'
import { Button, Modal } from 'antd'
import { AppPaths } from '../enums'

const ActivateAccount = () => {
  const { activateUser } = useActivateUser()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [success, setSuccess] = useState(false)
  const [visible, setVisible] = useState(false)

  const token = searchParams.get('token')

  const activate = async () => {
    if (token) {
      try {
        setIsLoading(true)
        const response = await activateUser({ token })
        setSuccess(true)
      } catch (error) {
        console.log(error)
      }
      setVisible(true)
      setIsDone(true)
      setIsLoading(false)
    }
  }

  const handleOk = () => {
    navigate(AppPaths.LOGIN)
  }

  return (
    <>
      <Modal
        open={visible}
        cancelText="Anuluj"
        okText="Przejdź do logowania"
        okButtonProps={{ className: 'bg-primaryBlue' }}
        centered
        onCancel={() => setVisible(false)}
        onOk={handleOk}
      >
        {success ? (
          <>
            <p className="text-2xl text-primaryBlue mb-4">Sukces!</p>
            <p className="text-lg">Konto zostało aktywowane. Zaloguj się!</p>
          </>
        ) : (
          <>
            <p className="text-2xl text-primaryBlue mb-4">
              Nie udało się potwierdzić adresu E-mail
            </p>
            <p className="text-lg">
              Spróbuj ponownie lub skontaktuj się z nami kontakt@myscribe.pl
            </p>
          </>
        )}
      </Modal>

      <div className="flex items-center justify-center w-screen h-screen">
        <div className="flex justify-center items-center flex-col w-[750px] shadow-default rounded-3xl bg-white px-12 pt-10 pb-10">
          <img src={require('../assets/landing/logo.png')} alt="logo" className="w-24 mb-4" />
          <p className="mb-4">To już ostatni krok!</p>
          <Button loading={isLoading} disabled={success || isLoading} onClick={activate}>
            Potwierdź adres E-mail
          </Button>
        </div>
      </div>
    </>
  )
}

export default ActivateAccount
