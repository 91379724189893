import React from 'react'

const HowWorksVideo = () => {
  return (
    <div className="mt-16 flex justify-center">
      <iframe
        width="711"
        height="400"
        src="https://www.youtube.com/embed/ihzhlj82Lhw"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default HowWorksVideo
