import { Form, Input, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { AppPaths, ErrorMessages } from 'enums'
import { LoginRequest } from 'types'
import { useLoginUser } from 'hooks'
import { useDispatch } from 'react-redux'
import { setToken } from 'store/auth/authSlice'

const LoginForm = () => {
  const [toast, contextHolder] = notification.useNotification()
  const navigate = useNavigate()
  const { loginUser } = useLoginUser()
  const dispatch = useDispatch()

  const onFinish = async (loginData: LoginRequest) => {
    try {
      const data = await loginUser(loginData)
      dispatch(setToken(data.accessToken))
      navigate(AppPaths.SUBSCRIPTION)
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === ErrorMessages.USER_NOT_FOUND) {
          toast.error({ message: 'Niepoprawne dane logowania.' })
        } else if (error.message === ErrorMessages.USER_NOT_ACTIVATED) {
          toast.error({ message: 'Użytkownik nie został aktywowany.' })
        } else {
          toast.error({ message: 'Nie udało się zalogować.' })
        }
      } else {
        toast.error({ message: 'Nie udało się zalogować.' })
      }
    }
  }

  const handleNavigateToResetPassword = () => {
    navigate(AppPaths.RESET_PASSWORD)
  }

  return (
    <>
      {contextHolder}
      <Form onFinish={onFinish} className="w-full">
        <p className="mb-2">Adres e-mail</p>
        <Form.Item<LoginRequest> name="email">
          <Input
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            placeholder={'Twój adres e-mail'}
            data-testid="email"
          />
        </Form.Item>
        <p className="mb-2">Hasło</p>
        <Form.Item<LoginRequest> name="password">
          <Input.Password
            className="bg-lightGray border-1 py-3 px-4 rounded-2xl hover:border-gray focus:border-primaryBlue"
            visibilityToggle={false}
            placeholder={'Twoje hasło'}
            data-testid="password"
          />
        </Form.Item>
        <p className="text-black">
          Nie pamiętasz hasła?{' '}
          <span className="font-bold cursor-pointer" onClick={handleNavigateToResetPassword}>
            Resetuj hasło.
          </span>
        </p>
        <button
          type="submit"
          className="bg-primaryBlue border-2 border-primaryBlue py-3 px-10 font-bold rounded-3xl box-border w-full text-white mt-6 hover:bg-transparent hover:text-primaryBlue transition duration-300"
        >
          Zaloguj się
        </button>
      </Form>
    </>
  )
}

export default LoginForm
