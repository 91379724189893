import { Divider } from "antd";
import { TranscriptSentence } from "assemblyai";
import { getSpeakerBlockFormat } from "helpers";
import { FC } from "react";
import { SpeakerBlock } from "types";

interface Props {
  assemblyaiSentences: TranscriptSentence[];
}

const TranscriptionSpeakerBlockContent: FC<Props> = ({
  assemblyaiSentences,
}) => {
  const speakerBlocks: SpeakerBlock[] =
    getSpeakerBlockFormat(assemblyaiSentences);
  return (
    <>
      {speakerBlocks.map((block, index) => {
        const text = block.sentences.map((sentence) => sentence.text).join(" ");

        return (
          <div className="" key={`sentence-block-${index}`}>
            {block.speaker && (
              <p className="mb-4 font-bold">Speaker {block.speaker}</p>
            )}
            <div className="flex flex-row items-start gap-3">
              <div className="w-full">
                <p className="text-wrap">{text}</p>
              </div>
            </div>
            <Divider className="my-2" />
          </div>
        );
      })}
    </>
  );
};

export default TranscriptionSpeakerBlockContent;
