import classNames from 'classnames'
import React, { FC } from 'react'

interface Props {
  text: string
  selected?: boolean
  type: 'primary-red' | 'primary-blue'
  onClick?: () => void
}

const LandingButton: FC<Props> = ({ text, type, selected, onClick }) => {
  const styles = classNames(
    'py-4 px-10 font-bold rounded-xl box-border',
    {
      'text-primaryWhite bg-primaryRed border-transparent hover:text-primaryRed hover:bg-primaryWhite transition duration-300 hover:border-primaryRed border-2':
        type === 'primary-red' && !selected,
    },
    {
      'bg-primaryBlue text-white border-transparent hover:text-primaryBlue hover:bg-transparent transition duration-300 hover:border-primaryBlue border-2':
        type === 'primary-blue' && !selected,
    },
    {
      'bg-gray text-white cursor-default': selected,
    },
  )
  return (
    <button onClick={onClick} className={styles}>
      {text}
    </button>
  )
}

export default LandingButton
