import { AdditionalProductInfo } from '../../../types'

export const additionalProductInfo: AdditionalProductInfo[] = [
  {
    priority: {
      data: 'Najniższy priorytet',
      description: 'Pliki tekstowe potrzebują trochę czas na wygenerowanie',
    },
    filesPerDay: {
      data: '2 pliki dziennie',
      description: 'Max ilość przepisanych plików na dzień',
    },
    fileSize: {
      data: '50 MB',
      description: 'Maksymalna rozmiar pojedynczego pliku',
    },
    fileLength: {
      data: '30 minut',
      description: 'Maksymalna długość pojedynczego pliku',
    },
  },
  {
    priority: {
      data: 'Wysoki priorytet',
      description: 'Tworzenie plików tekstowych jest przyspieszone',
    },
    filesPerDay: {
      data: 'Brak ograniczeń',
      description: 'Max ilość przepisanych plików na dzień',
    },
    fileSize: {
      data: '150 MB',
      description: 'Maksymalna rozmiar pojedynczego pliku',
    },
    fileLength: {
      data: 'Brak ograniczeń',
      description: 'Maksymalna długość pojedynczego pliku',
    },
  },
  {
    priority: {
      data: 'Najwyższy priorytet',
      description: 'Najwyższy priorytet przy tworzeniu plików tekstowych',
    },
    filesPerDay: {
      data: 'Brak ograniczeń',
      description: 'Max ilość przepisanych plików na dzień',
    },
    fileSize: {
      data: '300 MB',
      description: 'Maksymalna rozmiar pojedynczego pliku',
    },
    fileLength: {
      data: 'Brak ograniczeń',
      description: 'Maksymalna długość pojedynczego pliku',
    },
  },
]
