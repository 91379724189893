import React from 'react'
import { HowWorksCircle } from '..'
import HowWorksSvg from '../../../assets/landing/howWorks/HowWorksSvg'

const HowWorksRoute = () => {
  return (
    <div className="flex flex-row justify-between relative">
      <div className="absolute w-full top-[15%]">
        <HowWorksSvg />
      </div>
      <HowWorksCircle circleText="1" title="Prześlij plik" />
      <HowWorksCircle circleText="2" title="Poczekaj kilka sekund" />
      <HowWorksCircle circleText="3" title="Transkrypcja gotowa!" />
    </div>
  )
}

export default HowWorksRoute
