import { Button } from "antd";
import { AppPaths } from "enums";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Transcription } from "types";

interface Props {
  transcription: Transcription;
}

const TranscriptionTile: FC<Props> = ({ transcription }) => {
  const formattedDate = new Intl.DateTimeFormat("pl-PL", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(transcription.createdAt.toString()));

  return (
    // <div className="rounded-3xl bg-white hover:bg-gray transition duration-300 px-24 py-8 cursor-pointer">
    <div className="rounded-3xl bg-white transition duration-300 px-24 py-8">
      <div>
        <p className="text-3xl font-semibold mb-4">
          {transcription.transcriptionName}
        </p>
        <p>Nazwa pliku : {transcription.fileName}</p>
        {/* <p className="mb-4">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </p> */}
        <p>Data utworzenia : {formattedDate}</p>
      </div>
      <div className="w-full flex justify-end">
        <Link to={AppPaths.TRANSCRIPTIONS + "/" + transcription.id}>
          <button
            className="py-2 px-4 bg-primaryWhite text-primaryBlue rounded-3xl hover:bg-primaryBlue hover:text-primaryWhite transition duration-300 border-2 border-primaryBlue"
            // onClick={handleLogout}
          >
            Przejdź do transkrypcji
          </button>
        </Link>
      </div>
    </div>
  );
};

export default TranscriptionTile;
