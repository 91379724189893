import { Button, Input, Modal } from 'antd'
import { useCreateTranscription, useFetchMe, useFetchTranscriptions } from 'hooks'
import { ChangeEvent, FC, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { NotificationInstance } from 'antd/lib/notification/interface'
import { getMp3Duration } from '../../../../helpers'
import { ErrorMessages } from '../../../../enums'

interface Props {
  toast: NotificationInstance
  isAddTranscriptionModalVisible: boolean
  handleModalVisibility: () => void
}

const AddNewTranscriptionModal: FC<Props> = ({
  handleModalVisibility,
  isAddTranscriptionModalVisible,
  toast,
}) => {
  const { token } = useAppSelector(state => state.auth)
  const { createTranscription } = useCreateTranscription(token)
  const { mutate: mutateUser } = useFetchMe(token)
  const { mutate: mutateTranscriptions } = useFetchTranscriptions(token)
  const [file, setFile] = useState<File>()
  const [transcriptionName, setTranscriptionName] = useState<string>('')
  const [languageCode, setLanguageCode] = useState<string>('pl')
  const [speakers, setSpeakers] = useState<string>('single')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isButtonNotDisabled = file && transcriptionName && languageCode && speakers

  const handleClearForm = () => {
    setFile(undefined)
    setTranscriptionName('')
    setLanguageCode('pl')
    setSpeakers('single')
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    if (file) {
      const duration = await getMp3Duration(file)
      setFile(file)
    }
  }

  const handleLanguageCodeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLanguageCode(event.target.value)
  }

  const handleSpeakersChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSpeakers(event.target.value)
  }

  const handleTranscriptionNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTranscriptionName(event.target.value)
  }

  const handleCloseModalAndClearForm = () => {
    handleClearForm()
    handleModalVisibility()
  }

  const onSubmit = async () => {
    if (file && transcriptionName && languageCode && speakers) {
      try {
        setIsLoading(true)
        await createTranscription({
          file,
          languageCode,
          transcriptionName,
          speakerDetection: speakers === 'multiple',
        })
        setIsLoading(false)
        handleModalVisibility()
        toast.success({
          message: 'Transkrypcja została utworzona.',
          placement: 'bottomRight',
        })
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === ErrorMessages.TIME_LIMIT_EXCEEDED) {
            toast.error({ message: 'Niewystarczająca ilość czasu do stworzenia transkrypcji.' })
          } else if (error.message === ErrorMessages.FILE_TOO_LARGE) {
            toast.error({
              message: 'Plik jest zbyt duży, sprawdź swoje limity w zakładce Profil i subskrypcja.',
            })
          } else if (error.message === ErrorMessages.FILE_TOO_LONG) {
            toast.error({
              message:
                'Plik jest zbyt długi, sprawdź swoje limity w zakładce Profil i subskrypcja.',
            })
          }
        } else {
          toast.error({ message: 'Nie udało się zalogować.' })
        }
        setIsLoading(false)
      }
      await mutateTranscriptions()
      await mutateUser()
    }
  }

  return (
    <>
      <Modal
        footer={null}
        open={isAddTranscriptionModalVisible}
        onCancel={handleCloseModalAndClearForm}
        width={667}
      >
        <div className="px-9 py-7 flex flex-col items-center">
          <p className="text-4xl mb-6 font-semibold text-center text-primaryBlue">Prześlij plik</p>
          <div className="flex flex-col gap-3 w-full mb-12">
            <div className="flex flex-col">
              <label htmlFor="transcriptionName" className="mb-2">
                Nazwa transkrypcji
              </label>
              <Input
                id="transcriptionName"
                className="bg-lightGray border-0 py-3 px-4 rounded-2xl hover:bg-lightGray"
                placeholder={'Nazwa transkrypcji'}
                value={transcriptionName}
                onChange={handleTranscriptionNameChange}
              />
            </div>

            <div className="flex flex-col w-full items-start">
              <label htmlFor="language" className="mb-2">
                Język
              </label>
              <select
                id="language"
                onChange={handleLanguageCodeChange}
                className="bg-lightGray border-0 px-5 py-3 rounded-2xl w-full"
              >
                <option value="pl">Polski</option>
              </select>
            </div>
            <div className="flex flex-col w-full items-start">
              <label htmlFor="speakers" className="mb-2">
                Liczba mówców
              </label>
              <select
                id="speakers"
                onChange={handleSpeakersChange}
                className="bg-lightGray border-0 px-5 py-3 rounded-2xl w-full"
              >
                <option value="single">Jeden mówca</option>
                <option value="multiple">Wielu mówców</option>
              </select>
              <p className="text-sm mt-1">
                <span className="text-red-600">*</span>Wybór liczby mówców jest kluczowy dla
                poprawnego działania modelu
              </p>
            </div>
            <div>
              <p className="mb-2">Plik : {file?.name ? file.name : 'Nie wybrano'}</p>
              <button className="w-full bg-lightGray text-black py-3 px-5 rounded-2xl border-2 border-lightGray hover:bg-primaryWhite hover:text-primaryBlue transition duration-300 relative">
                <p className="cursor-pointer">Prześlij plik z komputera</p>
                <input
                  type="file"
                  accept=".mp3,.m4a,.ogg,.wav,.aac"
                  onChange={handleFileChange}
                  className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                />
              </button>
              <p className="mb-2">Obsługiwane formaty : mp4, m4a, acc, wav, ogg</p>
            </div>
          </div>
          <Button
            size="large"
            onClick={onSubmit}
            loading={isLoading}
            disabled={!isButtonNotDisabled}
          >
            Utwórz transkrypcję
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default AddNewTranscriptionModal
