import React, { FC, useState } from 'react'
import { User } from '../../../types/user.types'
import { PrimaryButton } from '../../common'
import { Button, InputNumber, InputNumberProps, Modal } from 'antd'
import { useAppSelector } from '../../../store/hooks'
import { useCreateAdditionalTimeCheckoutSession } from '../../../hooks/request'
import { Subscription } from '../../../types'

interface Props {
  user: User
  subscription?: Subscription
}

const AvailableMinutesInfo: FC<Props> = ({ user, subscription }) => {
  const { token } = useAppSelector(state => state.auth)
  const { createAdditionalTimeCheckoutSession } = useCreateAdditionalTimeCheckoutSession(token)
  const [visible, setVisible] = useState(false)
  const [hours, setHours] = useState(1)
  const {
    limit: { secondsLimitFromSubscription, additionalSeconds },
  } = user

  const handleBuyAdditionalTime = async () => {
    try {
      const response = await createAdditionalTimeCheckoutSession({ amount: hours })
      window.location.href = response
    } catch (error) {
      console.log(error)
    }
  }

  const handleModalOpen = () => {
    setVisible(true)
  }

  const handleModalClose = () => {
    setVisible(false)
  }

  const onChange: InputNumberProps['onChange'] = value => {
    if (value !== null) {
      setHours(Number(value))
    }
  }

  return (
    <>
      <Modal footer={null} open={visible} onCancel={handleModalClose} width={667}>
        <p className="text-primaryBlue text-2xl font-semibold mb-4">Wybierz ilość godzin</p>
        <InputNumber min={1} max={10} defaultValue={hours} onChange={onChange} />
        <p>Kwota : {Math.floor(hours * 29.99 * 100) / 100} zł</p>
        <Button onClick={handleBuyAdditionalTime} className="my-4">
          Dokup godziny
        </Button>
      </Modal>
      <div>
        <p className="mb-1 text-lg text-blackText">Minuty</p>
        <div className="w-2/3 bg-white rounded-xl py-8 px-16">
          <p className="text-primaryBlue text-2xl font-semibold mb-4">Dostępne minuty</p>
          <div className="mb-4">
            <p className="text-blackText">
              Czas z subskrypcji: {Math.floor(secondsLimitFromSubscription / 60)} min.
            </p>
            <p className="text-grayText text-xs">Odnawiany co miesiąc</p>
          </div>
          <p className="text-blackText mb-4">
            Czas dokupiony: {Math.floor(additionalSeconds / 60)} min.
          </p>
          {subscription?.currentPeriodEnd ? (
            <PrimaryButton title="Dokup minuty" onClick={handleModalOpen} />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default AvailableMinutesInfo
