export const getMp3Duration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    // Create an object URL for the file
    const url = URL.createObjectURL(file);
    // Create a new audio element
    const audio = new Audio(url);

    // Once the metadata has loaded, the duration is available
    audio.addEventListener("loadedmetadata", () => {
      // Resolve the promise with the duration
      resolve(audio.duration);
      // Clean up by revoking the object URL
      URL.revokeObjectURL(url);
    });

    // Handle error in loading the audio file
    audio.addEventListener("error", () => {
      reject(new Error("Failed to load audio file."));
      URL.revokeObjectURL(url);
    });
  });
};
