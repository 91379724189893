import { Button } from "antd";
import { AppPaths } from "enums";
import React from "react";
import { Link } from "react-router-dom";

const ErrorContent = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex justify-center items-center flex-col w-[750px] shadow-default rounded-3xl bg-white px-12 pt-28 pb-20">
        <p className="mb-20 text-4xl text-center font-semibold">
          Towja sesja wygasła. Zaloguj się ponownie.
        </p>
        <Link to={AppPaths.LOGIN}>
          <Button type="primary" className="bg-primaryBlue">
            Zaloguj się
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorContent;
