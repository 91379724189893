import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

export const getUserFormRules = () => {
  const nameRules: Rule[] = [
    {
      required: true,
      message: 'Pole wymagane',
    },
    {
      max: 45,
      message: 'Maksymalna liczba znaków to 45',
    },
  ]

  const lastNameRules: Rule[] = [
    {
      required: true,
      message: 'Pole wymagane',
    },
    {
      max: 45,
      message: 'Maksymalna liczba znaków to 45',
    },
  ]

  const emailRules: Rule[] = [
    {
      required: true,
      message: 'Pole wymagane',
    },
    {
      max: 45,
      message: 'Maksymalna liczba znaków to 45',
    },
    { type: 'email', message: 'Niepoprawny email' },
  ]

  const phoneNumberRules: Rule[] = [
    {
      required: false,
      pattern: /^\d{9}$/,
      message: 'Numer musi zawierać 9 cyfr',
    },
  ]

  return { emailRules, nameRules, lastNameRules, phoneNumberRules }
}
