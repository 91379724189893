import React from 'react'
import { PolicyContent } from '../../components/Policy'

const Policy = () => {
  return (
    <div>
      <PolicyContent />
    </div>
  )
}

export default Policy
