export enum ApiEndpoints {
  LOGIN = '/auth/login',
  LOGOUT = '/auth/logout',
  REGISTER = '/auth/register',
  ACTIVATE = '/auth/activate',
  RESET_PASSWORD = '/auth/reset-password',
  CHANGE_PASSWORD = '/auth/change-password',

  TRANSCRIPTION = '/transcription',
  ASSEMBLY_AI_TRANSCRIPTION = '/transcription/assemblyai',
  USER = '/user',
  ME = '/user/me',
  STRIPE = '/stripe',
  CHECKOUT_SESSION = '/stripe/checkout',
  ADDITIONAL_TIME_CHECKOUT_SESSION = '/stripe/additional-time-checkout',
  PORTAL_SESSION = '/stripe/portal',
  SUBSCRIPTION = '/stripe/subscriptions',
  INVOICE = '/stripe/invoices',
}
