import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { AppPaths } from '../../../../enums'

interface Props {
  to: AppPaths
  title: string
}

const FooterNavigationLink: FC<Props> = ({ to, title }) => {
  return <Link to={to}>{title}</Link>
}

export default FooterNavigationLink
