// import { LandingButton } from "@/app/(landing)/components";
import React, { FC } from 'react'
import { LandingButton } from '../index'
import { useNavigate, useNavigation } from 'react-router-dom'
import { AppPaths } from '../../../enums'
import { AdditionalProductInfo } from '../../../types'

interface Props {
  title: string
  description: string
  price: string
  priceDescription: string
  characteristics: AdditionalProductInfo
  minutes: number
}

const PriceOffer: FC<Props> = ({
  description,
  title,
  price,
  priceDescription,
  characteristics,
  minutes,
}) => {
  const navigate = useNavigate()
  const { fileSize, fileLength, filesPerDay, priority } = characteristics

  const handleNavigation = () => {
    navigate(AppPaths.REGISTER)
  }

  return (
    <div className="w-full mt-8 xl:mt-0 shadow-default rounded-[50px] p-10">
      <p className="text-primaryBlue text-3xl font-semibold">{title}</p>
      <p className="text-primaryBlue">{description}</p>
      <p className="text-primaryBlue mt-10 text-5xl font-semibold">{price}</p>
      <p className="text-darkGray mb-10">{priceDescription}</p>
      <LandingButton text="Wybierz" type="primary-red" onClick={handleNavigation} />
      <div className="mt-10">
        <p className="text-primaryBlue text-2xl font-semibold">{minutes} minut</p>
        <p className="text-primaryBlue">Ilość dostępnych minut transkrypcji audio</p>
      </div>
      <div className="mt-10">
        <p className="text-primaryBlue text-2xl font-semibold">{filesPerDay.data}</p>
        <p className="text-primaryBlue">{filesPerDay.description}</p>
      </div>
      <div className="mt-10">
        <p className="text-primaryBlue text-2xl font-semibold">{fileSize.data}</p>
        <p className="text-primaryBlue">{fileSize.description}</p>
      </div>
      <div className="mt-10">
        <p className="text-primaryBlue text-2xl font-semibold">{fileLength.data}</p>
        <p className="text-primaryBlue">{fileLength.description}</p>
      </div>
      <div className="mt-10">
        <p className="text-primaryBlue text-2xl font-semibold">{priority.data}</p>
        <p className="text-primaryBlue">{priority.description}</p>
      </div>
    </div>
  )
}

export default PriceOffer
