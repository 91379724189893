export enum AppPaths {
  ROOT = '/',
  LANDING = '/home',
  POLICY = '/home/policy',
  TERMS = '/home/terms',

  LOGIN = '/auth/login',
  REGISTER = '/auth/register',
  RESET_PASSWORD = '/auth/reset-password',
  ACTIVATE = '/auth/activate',
  CHANGE_PASSWORD = '/auth/change-password',

  MYSCRIBE = '/myscribe',
  TRANSCRIPTIONS = '/myscribe/transcriptions',
  USER_MANUAL = '/myscribe/user-manual',
  SUBSCRIPTION = '/myscribe/profile/subscription',
  PROFILE = '/myscribe/profile',
  INVOICES = '/myscribe/profile/invoices',
}
