import React from 'react'
import { CustomInnerLink, CustomLink } from '../../../../components/landing'
import { Link } from 'react-router-dom'
import { AppPaths } from '../../../../enums'

const LayoutHeader = () => {
  return (
    <div className="justify-center hidden md:flex pt-4">
      <div className="flex flex-row max-w-[1679px] w-full text-primaryBlue">
        <div className="flex flex-row items-center gap-10 w-1/2">
          <Link to={AppPaths.LANDING} className="w-[50px] lg:w-[75px] xl:w-[99px] ">
            <img src={require('../../../../assets/landing/logo.png')} width={99} height={99} />
          </Link>
          {/*<CustomLink href="/home">Usługi</CustomLink>*/}
          {/*<CustomLink href="/home">FAQ</CustomLink>*/}
          {/*<CustomLink href="/home">Cennik</CustomLink>*/}
          <CustomInnerLink href="#pricing">Cennik</CustomInnerLink>
          <CustomInnerLink href="#contact">Kontakt</CustomInnerLink>
        </div>
        <div className="flex flex-row items-center justify-end gap-8 w-1/2">
          <CustomLink href={AppPaths.LOGIN}>Zaloguj się</CustomLink>
          <Link to={AppPaths.REGISTER}>
            <button className="bg-primaryBlue text-white text-xl py-3 px-6 rounded-xl hover:text-primaryBlue hover:bg-transparent transition duration-300 hover:border-primaryBlue border-2">
              Stwórz konto
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LayoutHeader
