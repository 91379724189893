import React from 'react'

const PolicyContent = () => {
  return (
    <div>
      <div className="mb-3">
        <h1>Polityka Prywatności</h1>
        <h2>
          <strong>Definicje i interpretacja</strong>
        </h2>
        <p>W niniejszej polityce prywatności stosowane są następujące definicje:</p>
        <ol>
          <li>
            <strong>Dane</strong>– łącznie wszystkie informacje, które przesyłasz do FLC Studio
            Dariusz Konsek za pośrednictwem Witryny. Definicja ta obejmuje, w stosownych
            przypadkach, definicje zawarte w przepisach o ochronie danych;
          </li>
          <li>
            <strong>Pliki cookie</strong>– mały plik tekstowy umieszczany na komputerze użytkownika
            przez tę Witrynę, gdy użytkownik odwiedza określone części Witryny i/lub korzysta z
            niektórych funkcji Witryny. Szczegółowe informacje na temat plików cookie
            wykorzystywanych przez tę Witrynę znajdują się w poniższej klauzuli (
            <strong>Pliki cookie</strong>);
          </li>
          <li>
            <strong>Przepisy o ochronie danych osobowych</strong>– wszelkie obowiązujące przepisy
            dotyczące przetwarzania danych osobowych, w tym między innymi dyrektywa 96/46/WE
            (dyrektywa o ochronie danych) lub GDPR, a także wszelkie krajowe przepisy wykonawcze,
            wykonawcze i wtórne, tak długo, jak RODO obowiązuje w Belgii;
          </li>
          <li>
            <strong>GDPR</strong>– ogólne rozporządzenie o ochronie danych (UE) 2016/679;
          </li>
          <li>
            <strong>Unijne prawo dotyczące plików cookie</strong>– rozporządzenie z 2003 r. w
            sprawie prywatności i łączności elektronicznej (dyrektywa WE) zmienione rozporządzeniem
            z 2011 r. w sprawie prywatności i łączności elektronicznej (dyrektywa WE) (zmiana);
          </li>
          <li>
            <strong>Użytkownik/ty</strong>– każda osoba trzecia, która uzyskuje dostęp do Witryny i
            nie jest (i) zatrudniona przez FLC Studio Dariusz Konsek i nie działa w ramach swojego
            zatrudnienia lub (ii) nie jest zaangażowana jako konsultant lub w inny sposób nie
            świadczy usług na rzecz FLC Studio Dariusz Konsek i nie uzyskuje dostępu do Witryny w
            związku ze świadczeniem takich usług; i
          </li>
          <li>
            <strong>Strona internetowa</strong>– strona internetowa, z której aktualnie korzystasz,{' '}
            <a href="https://myscribe.pl">https://myscribe.pl/</a> oraz wszelkie subdomeny tej
            witryny, chyba że zostały wyraźnie wyłączone przez ich własne warunki.
          </li>
        </ol>
        <p>W niniejszej polityce prywatności, o ile z kontekstu nie wynika inna interpretacja:</p>
        <ol>
          <li>liczba pojedyncza obejmuje liczbę mnogą i odwrotnie;</li>
          <li>
            odniesienia do podpunktów, klauzul, załączników lub załączników odnoszą się do
            podpunktów, klauzul, załączników lub załączników do niniejszej Polityki prywatności;
          </li>
          <li>
            odniesienie do osoby obejmuje firmy, spółki, podmioty rządowe, fundusze powiernicze i
            spółki osobowe;
          </li>
          <li>"w tym" oznacza "w tym bez ograniczeń";</li>
          <li>
            odniesienie do jakiegokolwiek przepisu ustawowego obejmuje wszelkie jego modyfikacje lub
            poprawki;
          </li>
          <li>Nagłówki i podtytuły nie stanowią części niniejszej Polityki prywatności.</li>
        </ol>
        <h2>
          <strong>Zakres niniejszej polityki prywatności</strong>
        </h2>
        <p>
          Niniejsza polityka prywatności ma zastosowanie wyłącznie do działań FLC Studio Dariusz
          Konsek i Użytkowników w odniesieniu do tej strony internetowej. Nie obejmuje to żadnych
          stron internetowych, do których można uzyskać dostęp z tej Witryny, w tym między innymi
          wszelkich linków, które możemy udostępniać do witryn mediów społecznościowych.
        </p>
        <p>
          Dla celów obowiązujących przepisów o ochronie danych firma FLC Studio Dariusz Konsek jest
          "administratorem danych". Oznacza to, że określa cele i sposób przetwarzania Państwa
          danych.
        </p>
        <h2>
          <strong>Gromadzone dane</strong>
        </h2>
        <p>Możemy gromadzić następujące dane, w tym dane osobowe:</p>
        <ol>
          <li>nazwa;</li>
          <li>nazwa stanowiska;</li>
          <li>zawód;</li>
          <li>dane kontaktowe, takie jak adresy e-mail i numery telefonów;</li>
          <li>dane demograficzne, takie jak kod pocztowy, preferencje i zainteresowania;</li>
          <li>adres IP (zbierany automatycznie);</li>
          <li>typ i wersja przeglądarki internetowej (zbierane automatycznie);</li>
          <li>system operacyjny (zbierane automatycznie);</li>
          <li>adres fizyczny;</li>
          <li>dane dotyczące korzystania z naszej strony internetowej;</li>
          <li>zapis korespondencji, którą z nami prowadzisz;</li>
          <li>
            nagrania dźwiękowe spotkań (tylko spotkania, dla których użytkownik udzielił uprawnień
            do nagrywania)
          </li>
        </ol>
        <p>w każdym przypadku, zgodnie z niniejszą polityką prywatności.</p>
        <h2>
          <strong>W jaki sposób gromadzimy dane</strong>
        </h2>
        <p>Gromadzimy dane w następujący sposób:</p>
        <ol>
          <li>dane są nam przekazywane przez Ciebie;</li>
          <li>dane są otrzymywane z innych źródeł; i</li>
          <li>Dane są zbierane automatycznie.</li>
        </ol>
        <h3>
          <strong>Dane, które są nam przekazywane przez Ciebie</strong>
        </h3>
        <p>FLC Studio Dariusz Konsek gromadzi Państwa dane na wiele sposobów, na przykład:</p>
        <ol>
          <li>
            gdy kontaktujesz się z nami za pośrednictwem Witryny, telefonicznie, pocztą, pocztą
            elektroniczną lub w jakikolwiek inny sposób;
          </li>
          <li>
            kiedy rejestrujesz się u nas i zakładasz konto, aby otrzymywać nasze produkty/usługi;
          </li>
          <li>
            gdy wypełniasz ankiety, które wykorzystujemy do celów badawczych (chociaż nie jesteś
            zobowiązany do udzielenia na nie odpowiedzi);
          </li>
          <li>kiedy korzystasz z naszych usług;</li>
        </ol>
        <p>w każdym przypadku, zgodnie z niniejszą polityką prywatności.</p>
        <h3>
          <strong>Dane otrzymywane od osób trzecich</strong>
        </h3>
        <p>
          FLC Studio Dariusz Konsek będzie otrzymywać Dane o użytkowniku od następujących stron
          trzecich:
        </p>
        <ul>
          <li>Google Analytics</li>
        </ul>
        <h3>
          <strong>Dane, które są zbierane automatycznie</strong>
        </h3>
        <p>
          W zakresie, w jakim użytkownik uzyskuje dostęp do Witryny, gromadzimy jego dane
          automatycznie, na przykład:
        </p>
        <ol>
          <li>
            automatycznie zbieramy pewne informacje o Twojej wizycie na Stronie internetowej.
            Informacje te pomagają nam ulepszać zawartość i nawigację w Witrynie i obejmują adres IP
            użytkownika, datę, godziny i częstotliwość, z jaką użytkownik uzyskuje dostęp do Witryny
            oraz sposób, w jaki korzysta z jej treści i wchodzi z nią w interakcję.
          </li>
          <li>
            Będziemy zbierać Twoje dane automatycznie za pomocą plików cookie, zgodnie z
            ustawieniami plików cookie w Twojej przeglądarce. Więcej informacji na temat plików
            cookie i sposobu, w jaki wykorzystujemy je w Witrynie, zawiera poniższy punkt,
            zatytułowany "Pliki cookie".
          </li>
        </ol>
        <h2>
          <strong>Wykorzystywanie przez nas danych</strong>
        </h2>
        <ol>
          <li>
            Niektóre lub wszystkie z powyższych danych mogą być przez nas wymagane od czasu do czasu
            w celu zapewnienia najlepszej możliwej obsługi i doświadczenia podczas korzystania z
            naszej Witryny. W szczególności Dane mogą być przez nas wykorzystywane z następujących
            powodów:
          </li>
          <li>
            prowadzenie dokumentacji wewnętrznej;ulepszanie naszych produktów/usług;przesyłanie
            pocztą elektroniczną materiałów marketingowych, które mogą Cię zainteresować;kontakt w
            celu badania rynku, który może odbywać się za pośrednictwem poczty elektronicznej,
            telefonu, faksu lub poczty. Takie informacje mogą być wykorzystywane do dostosowywania
            lub aktualizowania Witryny;
          </li>
          <li>
            Możemy wykorzystywać Państwa dane do powyższych celów, jeśli uznamy to za konieczne dla
            naszych uzasadnionych interesów. Jeśli nie jesteś z tego zadowolony, masz prawo do
            sprzeciwu w pewnych okolicznościach (patrz sekcja zatytułowana "Twoje prawa" poniżej).
          </li>
          <li>
            W celu dostarczania Ci marketingu bezpośredniego za pośrednictwem poczty elektronicznej
            będziemy potrzebować Twojej zgody, niezależnie od tego, czy jest to opcja opt-in, czy
            soft-opt-in:
            <ol>
              <li>
                Zgoda typu soft opt-in to szczególny rodzaj zgody, który ma zastosowanie, gdy
                użytkownik wcześniej kontaktował się z nami (na przykład kontaktuje się z nami, aby
                poprosić nas o więcej szczegółów na temat konkretnego produktu/usługi, a my
                wprowadzamy na rynek podobne produkty/usługi). W przypadku zgody "soft opt-in"
                przyjmiemy udzieloną zgodę użytkownika, chyba że zrezygnuje.
              </li>
              <li>
                W przypadku innych rodzajów e-marketingu jesteśmy zobowiązani do uzyskania wyraźnej
                zgody użytkownika; Oznacza to, że wyrażając zgodę, musisz podjąć pozytywne i
                pozytywne działania, na przykład zaznaczając pole wyboru, które udostępnimy.
              </li>
              <li>
                Jeśli nie jesteś zadowolony z naszego podejścia do marketingu, masz prawo wycofać
                zgodę w dowolnym momencie. Aby dowiedzieć się, jak wycofać swoją zgodę, zapoznaj się
                z sekcją zatytułowaną "Twoje prawa" poniżej.
              </li>
            </ol>
          </li>
          <li>
            Kiedy rejestrujesz się u nas i zakładasz konto w celu korzystania z naszych usług,
            podstawą prawną tego przetwarzania jest wykonanie umowy między Tobą a nami i/lub
            podjęcie kroków, na Twoje żądanie, w celu zawarcia takiej umowy.
          </li>
          <li>
            Gromadzone przez nas dane, w tym informacje o spotkaniu, takie jak nazwa spotkania, link
            do spotkania, data i godzina, wykorzystujemy w następujących celach:
            <ol>
              <li>W celu świadczenia naszych usług i wypełniania naszych zobowiązań umownych.</li>
              <li>
                Ulepszanie i ulepszanie naszych produktów i usług, w tym optymalizacja planowania
                spotkań i zarządzania nimi.
              </li>
            </ol>
          </li>
        </ol>

        <h2>
          <strong>Komu udostępniamy dane</strong>
        </h2>
        <ol>
          <li>
            Możemy udostępniać dane użytkownika następującym grupom osób z następujących powodów:
            <ol>
              <li>
                naszym pracownikom, agentom i/lub profesjonalnym doradcom – aby umożliwić nam
                angażowanie się w marketing bezpośredni (taki jak biuletyny lub e-maile marketingowe
                dotyczące dostarczanych przez nas produktów i usług, które naszym zdaniem mogą Cię
                zainteresować).;
              </li>
              <li>
                zewnętrznym dostawcom usług płatniczych, którzy przetwarzają płatności dokonywane za
                pośrednictwem Witryny – w celu umożliwienia zewnętrznym dostawcom usług płatniczych
                przetwarzania płatności i zwrotów użytkowników;
              </li>
            </ol>
          </li>
          <li>
            Integracja z platformami innych firm:
            <p>
              Łącząc platformy stron trzecich, takie jak Kalendarz Google i Apple iCal, z naszymi
              Usługami, użytkownik udziela nam zgody na przesyłanie określonych informacji i danych,
              w tym imienia i nazwiska oraz adresu e-mail, do tych usług stron trzecich w jego
              imieniu. Ma to na celu umożliwienie bezproblemowej integracji i poprawę komfortu
              użytkowania.
            </p>
          </li>
        </ol>
        <p>w każdym przypadku, zgodnie z niniejszą polityką prywatności.</p>

        <h2>
          <strong>Dbanie o bezpieczeństwo danych</strong>
        </h2>
        <ol>
          <li>
            Będziemy stosować środki techniczne i organizacyjne w celu ochrony Państwa danych, na
            przykład:
            <ol>
              <li>
                Dostęp do konta użytkownika jest kontrolowany za pomocą hasła i nazwy użytkownika,
                które są unikalne dla użytkownika.
              </li>
              <li>Przechowujemy Twoje dane na bezpiecznych serwerach.</li>
            </ol>
          </li>
          <li>
            Środki techniczne i organizacyjne obejmują środki mające na celu postępowanie w
            przypadku podejrzenia naruszenia ochrony danych. W przypadku podejrzenia niewłaściwego
            wykorzystania, utraty lub nieuprawnionego dostępu do swoich Danych, prosimy o
            niezwłoczne poinformowanie nas o tym, kontaktując się z nami za pośrednictwem tego
            adresu e-mail: kontakt@myscribe.pl
          </li>
          <li>
            Jeśli chcesz uzyskać szczegółowe informacje od Get Safe Online na temat ochrony swoich
            informacji oraz komputerów i urządzeń przed oszustwami, kradzieżą tożsamości, wirusami i
            wieloma innymi problemami online, odwiedź stronę{' '}
            <a href="https://www.getsafeonline.org/">www.getsafeonline.org</a>.
          </li>
        </ol>

        <h2>
          <strong>Przechowywanie danych</strong>
        </h2>
        <ol>
          <li>
            O ile dłuższy okres przechowywania nie jest wymagany lub dozwolony przez prawo, będziemy
            przechowywać Państwa dane w naszych systemach wyłącznie przez okres niezbędny do
            realizacji celów określonych w niniejszej polityce prywatności lub do momentu, gdy
            zażądają Państwo ich usunięcia.
          </li>
          <li>
            Nawet jeśli usuniemy Twoje dane, mogą one pozostać na nośnikach kopii zapasowych lub
            archiwalnych do celów prawnych, podatkowych lub regulacyjnych.
          </li>
        </ol>
        <h2>
          <strong>Przysługujące Państwu prawa</strong>
        </h2>
        <ol>
          <li>
            Użytkownikowi przysługują następujące prawa w odniesieniu do jego Danych:
            <ol>
              <li>
                <strong>Prawo dostępu do danych</strong>– prawo do żądania (i) kopii informacji,
                które przechowujemy na Twój temat, w dowolnym momencie lub (ii) modyfikacji,
                aktualizacji lub usunięcia takich informacji. Jeśli zapewnimy Ci dostęp do
                informacji, które przechowujemy na Twój temat, nie obciążymy Cię za to opłatą, chyba
                że Twoje żądanie jest "ewidentnie nieuzasadnione lub nadmierne". Tam, gdzie jest to
                prawnie dozwolone, możemy odrzucić Twoją prośbę. Jeśli odrzucimy Twoją prośbę,
                poinformujemy Cię o przyczynach.
              </li>
              <li>
                <strong>Prawo do sprostowania</strong>– prawo do sprostowania swoich danych, jeśli
                są one niedokładne lub niekompletne.
              </li>
              <li>
                <strong>Prawo do usunięcia danych</strong>– prawo do żądania usunięcia Twoich Danych
                z naszych systemów.
              </li>
              <li>
                <strong>Prawo do ograniczenia wykorzystywania przez nas Państwa danych</strong>–
                prawo do "zablokowania" nam możliwości korzystania z Twoich Danych lub ograniczenia
                sposobu, w jaki możemy z nich korzystać.
              </li>
              <li>
                <strong>Prawo do przenoszenia danych</strong>– prawo do żądania od nas
                przeniesienia, skopiowania lub przeniesienia Twoich Danych.
              </li>
              <li>
                <strong>Prawo do sprzeciwu</strong>– prawo do sprzeciwu wobec wykorzystywania przez
                nas Państwa Danych, w tym w przypadku, gdy wykorzystujemy je w naszych uzasadnionych
                interesach.
              </li>
            </ol>
          </li>
          <li>
            Aby złożyć zapytanie, skorzystać z któregokolwiek z praw określonych powyżej lub wycofać
            zgodę na przetwarzanie swoich danych (w przypadku, gdy zgoda jest podstawą prawną
            przetwarzania Twoich danych), skontaktuj się z nami za pośrednictwem tego adresu e-mail:
            kontakt@myscribe.pl
          </li>
          <li>
            Jeśli nie jesteś zadowolony ze sposobu, w jaki rozpatrujemy Twoją skargę dotyczącą
            Twoich Danych, możesz skierować swoją skargę do odpowiedniego organu ochrony danych. W
            przypadku Irlandii jest to Komisja Ochrony Danych (DPC). Dane kontaktowe DPC można
            znaleźć na ich stronie internetowej pod adresem{' '}
            <a href="https://www.dataprotection.ie/">https://www.dataprotection.ie/</a>.
          </li>
          <li>
            Ważne jest, aby Dane, które posiadamy na Twój temat, były dokładne i aktualne. Prosimy o
            informowanie nas o wszelkich zmianach Państwa danych w okresie, przez który je
            przechowujemy.
          </li>
        </ol>

        <h2>
          <strong>Linki do innych stron internetowych</strong>
        </h2>
        <p>
          Niniejsza strona internetowa może od czasu do czasu zawierać linki do innych stron
          internetowych. Nie mamy kontroli nad takimi stronami internetowymi i nie ponosimy
          odpowiedzialności za ich treść. Niniejsza polityka prywatności nie obejmuje korzystania z
          takich stron internetowych. Zaleca się zapoznanie się z polityką prywatności lub
          oświadczeniami innych stron internetowych przed skorzystaniem z nich.
        </p>

        <h2>
          <strong>Pliki cookie</strong>
        </h2>
        <ol>
          <li>
            Ta strona internetowa może umieszczać i uzyskiwać dostęp do niektórych plików cookie na
            komputerze użytkownika. FLC Studio Dariusz Konsek wykorzystuje pliki cookie w celu
            poprawy komfortu korzystania z Witryny oraz w celu ulepszenia naszej oferty usług. Firma
            FLC Studio Dariusz Konsek starannie wybrała te pliki cookie i podjęła kroki w celu
            zapewnienia, że prywatność użytkownika jest zawsze chroniona i szanowana.
          </li>
          <li>
            Ta strona internetowa może umieszczać następujące pliki cookie: Poniżej znajduje się
            lista plików cookie, których używamy. Staraliśmy się, aby były one kompletne i aktualne,
            ale jeśli uważasz, że pominęliśmy plik cookie lub wystąpiły jakiekolwiek rozbieżności,
            poinformuj nas o tym.
            <ol>
              <li>
                Niezbędne pliki cookie – są to pliki cookie, które są wymagane do działania naszej
                strony internetowej. Należą do nich na przykład pliki cookie, które umożliwiają
                logowanie się do bezpiecznych obszarów naszej strony internetowej, korzystanie z
                koszyka na zakupy lub korzystanie z usług e-fakturowania.
              </li>
              <li>
                Analityczne/wydajnościowe pliki cookie – pozwalają nam rozpoznać i policzyć liczbę
                odwiedzających oraz zobaczyć, w jaki sposób odwiedzający poruszają się po naszej
                stronie internetowej, gdy z niej korzystają. Pomaga nam to ulepszyć sposób działania
                naszej strony internetowej, na przykład poprzez zapewnienie, że użytkownicy łatwo
                znajdą to, czego szukają.
              </li>
              <li>
                Funkcjonalne pliki cookie – służą do rozpoznania użytkownika po powrocie na naszą
                stronę internetową. Dzięki temu możemy spersonalizować nasze treści dla użytkownika,
                powitać go po imieniu i zapamiętać jego preferencje (na przykład wybór języka lub
                regionu).
              </li>
            </ol>
          </li>
          <li>
            Listę plików cookie, których używamy, można znaleźć w Harmonogramie plików cookie.
          </li>
          <li>
            Użytkownik może włączyć lub wyłączyć pliki cookie w swojej przeglądarce internetowej.
            Domyślnie większość przeglądarek internetowych akceptuje pliki cookie, ale można to
            zmienić. Aby uzyskać więcej informacji, zapoznaj się z menu pomocy w swojej przeglądarce
            internetowej.
          </li>
          <li>
            Użytkownik może w każdej chwili usunąć pliki cookie; Użytkownik może jednak utracić
            wszelkie informacje, które umożliwiają szybszy i skuteczniejszy dostęp do Witryny, w tym
            między innymi ustawienia personalizacji.
          </li>
          <li>
            Zaleca się upewnienie się, że przeglądarka internetowa jest aktualna i skorzystanie z
            pomocy i wskazówek dostarczonych przez twórcę przeglądarki internetowej, jeśli nie masz
            pewności co do dostosowania ustawień prywatności.
          </li>
          <li>
            Więcej informacji na temat plików cookie, w tym sposobu ich wyłączania, można znaleźć w{' '}
            <a href="https://www.aboutcookies.org/">aboutcookies.org</a>. Znajdziesz tam również
            szczegółowe informacje na temat usuwania plików cookie z komputera.
          </li>
        </ol>

        <h2>
          <strong>Zrzeczenie się</strong>
        </h2>
        <ol>
          <li>
            Używanie przez MyScribe i przesyłanie do dowolnej innej aplikacji informacji otrzymanych
            z Google interfejsów API będzie zgodne z Zasadami dotyczącymi danych użytkownika usług
            Google API, w tym z wymaganiami dotyczącymi ograniczonego użytkowania.
          </li>
        </ol>
        {/*tu do sprawdzenia*/}
        <h2>
          <strong>Ogólne</strong>
        </h2>
        <ol>
          <li>
            Użytkownik nie może przenieść żadnych swoich praw wynikających z niniejszej polityki
            prywatności na żadną inną osobę. Możemy przenieść nasze prawa wynikające z niniejszej
            polityki prywatności, jeśli mamy uzasadnione przekonanie, że nie będzie to miało wpływu
            na Twoje prawa.
          </li>
          <li>
            Jeśli jakikolwiek sąd lub właściwy organ uzna, że którekolwiek z postanowień niniejszej
            polityki prywatności (lub część któregokolwiek z postanowień) jest nieważne, niezgodne z
            prawem lub niewykonalne, postanowienie to lub jego część, w wymaganym zakresie, zostanie
            uznane za usunięte, a ważność i wykonalność pozostałych postanowień niniejszej polityki
            prywatności nie zostanie naruszona.
          </li>
          <li>
            O ile nie uzgodniono inaczej, żadne opóźnienie, działanie lub zaniechanie ze strony w
            wykonaniu jakiegokolwiek prawa lub środka prawnego nie będzie uważane za zrzeczenie się
            tego lub jakiegokolwiek innego prawa lub środka prawnego.
          </li>
        </ol>

        <h2>
          <strong>Dzieci poniżej 16 roku życia</strong>
        </h2>
        <ol>
          <li>
            Serwis nie jest skierowany do dzieci poniżej 16 roku życia. Nie gromadzimy świadomie
            danych osobowych od dzieci poniżej 16 roku życia. Jeśli masz mniej niż 16 lat, nie
            korzystaj z naszych usług ani nie przekazuj nam żadnych informacji za pośrednictwem
            Witryny. Jeśli dowiemy się, że zebraliśmy lub otrzymaliśmy dane osobowe od dziecka
            poniżej 16 roku życia bez weryfikacji zgody rodziców, usuniemy te informacje. Jeśli
            uważasz, że możemy mieć jakiekolwiek informacje od dziecka poniżej 16 roku życia lub na
            jego temat, skontaktuj się z nami pod adresem kontakt@myscribe.pl
          </li>
        </ol>

        <h2>
          <strong>Zmiany w niniejszej polityce prywatności</strong>
        </h2>
        <ol>
          <li>
            FLC Studio Dariusz Konsek zastrzega sobie prawo do zmiany niniejszej polityki
            prywatności, jeśli uznamy to za konieczne lub jeśli będzie to wymagane przez prawo.
            Wszelkie zmiany zostaną niezwłocznie opublikowane na Stronie internetowej i uznaje się,
            że użytkownik zaakceptował warunki polityki prywatności przy pierwszym skorzystaniu z
            Witryny po wprowadzeniu zmian.
          </li>
        </ol>
      </div>
    </div>
  )
}

export default PolicyContent
