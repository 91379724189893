import React from 'react'
import { LandingButton, PriceOffer } from '..'
import { additionalProductInfo } from '../../Subscription/NoSubscriptionContent/aditionalProductInfo'

interface Characteristics {
  title: string
  description: string
}

const sth = additionalProductInfo

const freeCharacteristics: Characteristics[] = [
  {
    title: '2 pliki dziennie',
    description: 'Codziennie bezpłatnie transkrybuj 3 pliki.',
  },
  {
    title: '30 minutowe pliki',
    description: 'Każdy plik może mieć maksymalnie 30 minut. Przesyłaj 1 plik na raz.',
  },
  {
    title: 'Niższy priorytet',
    description: 'Poczekaj dłużej, zanim Twoje pliki zostaną przepisane.',
  },
]

const PriceSection = () => {
  return (
    <div className="mt-40 w-full" id="pricing">
      <p className="text-primaryBlue text-center text-4xl font-semibold mb-12">Cennik</p>
      {/*<div className="flex justify-center items-center">*/}
      {/*  <div className="shadow-default flex flex-row justify-between rounded-[50px] py-2 px-4">*/}
      {/*    <LandingButton text="Miesięczny" type="primary-blue" />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="flex flex-col xl:flex-row gap-x-6 w-full bg-red mt-24">
        <PriceOffer
          title="BASIC"
          description=""
          price="49 PLN"
          priceDescription="Miesięcznie"
          characteristics={additionalProductInfo[0]}
          minutes={120}
        />
        <PriceOffer
          title="PREMIUM"
          description=""
          price="99 PLN"
          priceDescription="Miesięcznie"
          characteristics={additionalProductInfo[1]}
          minutes={300}
        />
        <PriceOffer
          title="ENTERPRISE"
          description=""
          price="149 PLN"
          priceDescription="Miesięcznie"
          characteristics={additionalProductInfo[2]}
          minutes={600}
        />
      </div>
    </div>
  )
}

export default PriceSection
