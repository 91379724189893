import { Rule } from 'antd/es/form'

export const getCompanyFormRules = () => {
  const companyNameRules: Rule[] = [
    {
      required: true,
      message: 'Pole wymagane',
    },
    {
      max: 45,
      message: 'Maksymalna liczba znaków to 45',
    },
  ]

  const nipRules: Rule[] = [
    {
      required: true,
      pattern: /^\d{10}$/,
      message: 'NIP musi zawierać 10 cyfr',
    },
  ]

  const addressRules: Rule[] = [
    {
      required: true,
      message: 'Pole wymagane',
    },
    {
      max: 45,
      message: 'Maksymalna liczba znaków to 45',
    },
  ]

  const postalCodeRules: Rule[] = [
    {
      required: true,
      pattern: /^\d{2}-\d{3}$/,
      message: 'Podaj prawidłowy kod pocztowy',
    },
  ]

  const cityRules: Rule[] = [
    {
      required: true,
      message: 'Pole wymagane',
    },
    {
      max: 45,
      message: 'Maksymalna liczba znaków to 45',
    },
  ]

  return { addressRules, cityRules, nipRules, companyNameRules, postalCodeRules }
}
