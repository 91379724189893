import React from 'react'
import { TermsContent } from '../../components/Terms'

const Terms = () => {
  return (
    <div>
      <TermsContent />
    </div>
  )
}

export default Terms
