import { ChangePasswordRequest, RegisterRequest, ResetPasswordRequest } from 'types'
import { ApiEndpoints } from 'enums'
import { axiosClient } from 'helpers'

export const useChangePassword = () => {
  const changePassword = async ({ password, token }: ChangePasswordRequest) => {
    try {
      const requestBody: ChangePasswordRequest = { password, token }
      return await axiosClient(null).post(ApiEndpoints.CHANGE_PASSWORD, requestBody)
    } catch (error) {
      throw new Error('Change password error')
    }
  }

  return { changePassword }
}
