import { RegisterRequest, ResetPasswordRequest } from 'types'
import { ApiEndpoints } from 'enums'
import { axiosClient } from 'helpers'

export const useResetPassword = () => {
  const resetPassword = async ({ email }: ResetPasswordRequest) => {
    try {
      const requestBody: ResetPasswordRequest = { email }
      return await axiosClient(null).post(ApiEndpoints.RESET_PASSWORD, requestBody)
    } catch (error) {
      throw new Error('Reset password error')
    }
  }

  return { resetPassword }
}
