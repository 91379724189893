import { FC } from 'react'
import { AdditionalProductInfo, Product } from 'types'
import { useCreateCheckoutSession } from '../../../hooks/request'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../../store/hooks'
import { Button } from 'antd'

interface Props {
  product: Product
  additionalProductInfo: AdditionalProductInfo
}

const SubscriptionProductTile: FC<Props> = ({ product, additionalProductInfo }) => {
  const { token } = useAppSelector(state => state.auth)
  const { createCheckoutSession } = useCreateCheckoutSession(token)

  const { priority, fileSize, fileLength, filesPerDay } = additionalProductInfo
  const { name, description, price, seconds } = product

  const handleCreateCheckoutSession = async () => {
    try {
      const response = await createCheckoutSession({ priceId: product.priceId })
      window.location.href = response
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full bg-white rounded-3xl px-8 py-8 shadow-2xl">
      <div className="mb-8">
        <h3 className="text-2xl font-semibold">{name}</h3>
        <p className="text-sm font-thin">{description}</p>
      </div>
      <div className="mb-8">
        <p className="text-3xl font-bold">{price / 100} PLN</p>
        <p className="text-menuGray font-light">Miesięczne rozliczenie</p>
      </div>
      <button
        className="py-2 px-4 mb-16 bg-primaryRed text-primaryWhite rounded-xl font-semibold hover:bg-primaryWhite hover:text-primaryRed transition duration-300 border-2 border-primaryRed"
        onClick={handleCreateCheckoutSession}
      >
        Wybieram
      </button>
      <div className="flex flex-col gap-y-4">
        <div>
          <p className="font-semibold text-xl">{seconds / 60} minut</p>
          <p>Ilość dostępnych minut transkrypcji audio</p>
        </div>
        <div>
          <p className="font-semibold text-xl">{filesPerDay.data}</p>
          <p>{filesPerDay.description}</p>
        </div>
        <div>
          <p className="font-semibold text-xl">{fileLength.data}</p>
          <p>{fileLength.description}</p>
        </div>
        <div>
          <p className="font-semibold text-xl">{fileSize.data}</p>
          <p>{fileSize.description}</p>
        </div>
        <div>
          <p className="font-semibold text-xl">{priority.data}</p>
          <p>{priority.description}</p>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionProductTile
