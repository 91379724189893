import { Divider } from "antd";
import { TranscriptSentence } from "assemblyai";
import { convertMillisecondsToTime } from "helpers";
import React, { FC } from "react";

interface Props {
  assemblyaiSentences: TranscriptSentence[];
}

const TranscriptionSentenceContent: FC<Props> = ({ assemblyaiSentences }) => {
  return (
    <>
      {assemblyaiSentences.map((sentence, index) => {
        return (
          <div className="" key={`sentence-block-${index}`}>
            {sentence.speaker && <p>Speaker {sentence.speaker}</p>}
            <div className="flex flex-row items-start gap-3">
              <div className="flex flex-row items-end w-40">
                <p>{convertMillisecondsToTime(sentence.start)}</p>
                <p className="mx-2"> - </p>
                <p>{convertMillisecondsToTime(sentence.end)}</p>
              </div>
              <div className="w-full">
                <p className="text-wrap">{sentence.text}</p>
              </div>
            </div>
            <Divider className="my-2" />
          </div>
        );
      })}
    </>
  );
};

export default TranscriptionSentenceContent;
