import { useState } from 'react'
import AddNewTranscriptionModal from '../AddNewTranscriptionModal'
import { notification } from 'antd'
import { useLogoutUser } from 'hooks'
import { useAppSelector } from 'store/hooks'
import { useDispatch } from 'react-redux'
import { clearToken } from 'store/auth/authSlice'
import { Link, useNavigate } from 'react-router-dom'
import { AppPaths } from 'enums'
import { LimitBox, TranscriptionsScrollView } from '..'

const SiderContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useAppSelector(state => state.auth)
  const { logoutUser } = useLogoutUser()
  const [isAddTranscriptionModalVisible, setIsAddTranscriptionModalVisible] = useState(false)

  const [toast, contextHolder] = notification.useNotification()

  const handleModalVisibility = () => {
    setIsAddTranscriptionModalVisible(!isAddTranscriptionModalVisible)
  }

  const handleLogout = async () => {
    await logoutUser(token)
    dispatch(clearToken())
    navigate(AppPaths.LOGIN)
  }

  return (
    <>
      {contextHolder}
      <AddNewTranscriptionModal
        toast={toast}
        handleModalVisibility={handleModalVisibility}
        isAddTranscriptionModalVisible={isAddTranscriptionModalVisible}
      />
      <div className="flex flex-col justify-between">
        <div>
          <div className="flex flex-col px-12 mt-8">
            <button
              className="py-2 px-4 mb-16 bg-primaryBlue text-primaryWhite rounded-xl hover:bg-primaryWhite hover:text-primaryBlue transition duration-300 border-2 border-primaryBlue"
              onClick={handleModalVisibility}
            >
              Stwórz transkrypcję
            </button>
          </div>
          <TranscriptionsScrollView />
        </div>
      </div>
    </>
  )
}

export default SiderContent
