import { Provider } from 'react-redux'
import store from './store/store'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  Login,
  Transcriptions,
  UserManual,
  Transcription,
  Register,
  Subscription,
  Profile,
  Landing,
  ActivateAccount,
  ResetPassword,
  ChangePassword,
  TransactionResult,
  Policy,
  Terms,
  Invoices,
} from 'pages'
import { AuthLayout, ProfileLayout, TranscriptionLayout } from 'layouts'
import LandingLayout from './layouts/LandingLayout'

function App() {
  const MyscribePaths = () => (
    <AuthLayout>
      {/*<TranscriptionLayout>*/}
      <Routes>
        <Route path={'/profile/*'} element={<ProfilePaths />} />
        <Route path={'/transcriptions/*'} element={<TranscriptionPaths />} />
        {/*<Route path={'/transcriptions'} element={<Transcriptions />} />*/}
        {/*<Route path={`/transcriptions/:id`} element={<Transcription />} />*/}
        {/*<Route path={'/user-manual'} element={<UserManual />} />*/}
        {/*<Route path={'/transaction-result'} element={<TransactionResult />} />*/}
      </Routes>
      {/*</TranscriptionLayout>*/}
    </AuthLayout>
  )

  const TranscriptionPaths = () => (
    // <AuthLayout>
    <TranscriptionLayout>
      <Routes>
        {/*<Route path={'/profile'} element={<Profile />} />*/}
        {/*<Route path={'/subscription'} element={<Subscription />} />*/}
        <Route path={'/'} element={<Transcriptions />} />
        <Route path={`/:id`} element={<Transcription />} />
        {/*<Route path={'/user-manual'} element={<UserManual />} />*/}
        <Route path={'/transaction-result'} element={<TransactionResult />} />
      </Routes>
    </TranscriptionLayout>
    // </AuthLayout>
  )

  const ProfilePaths = () => (
    <ProfileLayout>
      <Routes>
        <Route path={'/'} element={<Profile />} />
        <Route path={'/subscription'} element={<Subscription />} />
        <Route path={'/invoices'} element={<Invoices />} />
        {/*<Route path={'/transcriptions'} element={<Transcriptions />} />*/}
        {/*<Route path={`/transcriptions/:id`} element={<Transcription />} />*/}
        {/*<Route path={'/transaction-result'} element={<TransactionResult />} />*/}
      </Routes>
    </ProfileLayout>
  )

  const AuthPaths = () => (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/activate" element={<ActivateAccount />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
    </Routes>
  )

  const LandingPaths = () => (
    <LandingLayout>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </LandingLayout>
  )

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home/*" element={<LandingPaths />} />
        <Route path="/auth/*" element={<AuthPaths />} />
        <Route path="/myscribe/*" element={<MyscribePaths />} />
      </Routes>
    </Provider>
  )
}

export default App
