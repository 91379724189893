import React from 'react'

const MobileSoon = () => {
  return (
    <div className="flex justify-center items-center flex-col">
      <p className="text-2xl mb-4">Wersja mobilna wkrótce</p>
      <img src={require('../../../assets/landing/logo.png')} width={150} height={150} />
    </div>
  )
}

export default MobileSoon
