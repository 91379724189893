import { RegisterRequest } from 'types'
import { ApiEndpoints } from 'enums'
import { axiosClient } from 'helpers'

export const useRegisterUser = () => {
  const registerUser = async ({ email, password, firstName, lastName }: RegisterRequest) => {
    try {
      const requestBody: RegisterRequest = { email, password, firstName, lastName }
      const response = await axiosClient(null).post(ApiEndpoints.REGISTER, requestBody)
      return response.data
    } catch (error) {
      throw new Error('Register error')
    }
  }

  return { registerUser }
}
