import useSWR from 'swr'
import { User } from '../../types/user.types'
import { AxiosError } from 'axios'
import { ApiEndpoints } from '../../enums'
import { axiosFetcher } from '../../helpers'
import { Invoice } from '../../types'

export const useFetchInvoices = (token: string | null) => {
  const { data, error, isLoading, mutate } = useSWR<Invoice[], AxiosError>(
    ApiEndpoints.INVOICE,
    axiosFetcher(token),
  )

  return { invoices: data, error, isLoading, mutate }
}
