import { Button, notification } from "antd";
import { TranscriptionContent } from "components/Transcription";
import { ContentLoader, ErrorContent } from "components/common";
import {
  useFetchTranscription,
  useGenerateAndGetTranscriptionSummary,
  useGenerateSrt,
  useGenerateTxt,
} from "hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

const Transcription = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { id } = useParams();
  const { generateSrtString } = useGenerateSrt();
  const { generateTxtString } = useGenerateTxt();
  const { generateAndGetTranscriptionSummary } =
    useGenerateAndGetTranscriptionSummary();
  const { transcription, error, isLoading, mutate } = useFetchTranscription(
    id as string,
    token
  );

  const [toast, contextHolder] = notification.useNotification();

  const [isTxtLoading, setIsTxtLoading] = useState<boolean>(false);
  const [isSrtLoading, setIsSrtLoading] = useState<boolean>(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState<boolean>(false);

  const handleGenerateTxt = async () => {
    setIsTxtLoading(true);
    const data = await generateTxtString(transcription?.assemblyAiId, token);
    setIsTxtLoading(false);

    const blob = new Blob([data], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "result.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleGenerateSummary = async () => {
    setIsSummaryLoading(true);
    try {
      const data = await generateAndGetTranscriptionSummary(
        transcription?.id,
        token
      );
      const blob = new Blob([data], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "result.txt";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      await mutate();
    } catch {
      toast.error({ message: "Nie udało się wygenerować podsumowania" });
    }

    setIsSummaryLoading(false);
  };

  const handleGenerateSrt = async () => {
    setIsSrtLoading(true);
    const data = await generateSrtString(transcription?.assemblyAiId, token);
    setIsSrtLoading(false);

    const blob = new Blob([data], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "result.srt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (isLoading) return <ContentLoader />;

  if (error || !transcription) {
    if (error?.response?.status === 403) {
      return <ErrorContent />;
    }
    return <p>Błąd przy wczytywaniu transkrypcji</p>;
  }

  return (
    <>
      {contextHolder}
      <div className="flex flex-row w-full justify-end gap-4">
        <Button
          size="large"
          className="border-2 border-primaryBlue rounded-2xl"
          onClick={handleGenerateSrt}
          loading={isSrtLoading}
        >
          Generuj i pobierz .srt
        </Button>
        <Button
          size="large"
          className="border-2 border-primaryBlue rounded-2xl"
          onClick={handleGenerateTxt}
          loading={isTxtLoading}
        >
          Generuj i pobierz .txt
        </Button>
        <Button
          size="large"
          className="border-2 border-primaryBlue rounded-2xl"
          onClick={handleGenerateSummary}
          loading={isSummaryLoading}
          disabled={transcription.summary}
        >
          Generuj i pobierz podsumowanie
        </Button>
      </div>
      <p className="pb-6 font-semibold text-4xl">
        {transcription.transcriptionName}
      </p>
      <TranscriptionContent transcription={transcription} />
    </>
  );
};

export default Transcription;
