import React, { FC } from 'react'

interface Props {
  title: string
  description: string
  src: string
}

const WhyUsFeature: FC<Props> = ({ description, src, title }) => {
  return (
    <div className="flex flex-row items-center w-[45%]">
      <div className="h-full mr-4">
        <img src={src} width={60} height={67} />
      </div>
      <div>
        <p className="text-primaryBlue mb-4 text-2xl font-semibold">{title}</p>
        <p className="text-primaryBlue text-xl">{description}</p>
      </div>
    </div>
  )
}

export default WhyUsFeature
