import React, { FC, ReactNode } from 'react'
import { Layout } from 'antd'
import { FooterContent, LayoutHeader } from './components'

interface Props {
  children: ReactNode
}

const LandingLayout: FC<Props> = ({ children }) => {
  const { Content, Footer, Header, Sider } = Layout
  return (
    <>
      <Layout className="w-full min-h-screen">
        <Header className="bg-transparent h-32">
          <LayoutHeader />
        </Header>
        <Layout>
          <Content className="bg-primary md:px-16 lg:px-32 xl:px-48 2xl:px-64 mb-10">
            {children}
          </Content>
        </Layout>
        <Footer>
          <FooterContent />
        </Footer>
      </Layout>
    </>
  )
}

export default LandingLayout
