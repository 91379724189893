import { ApiEndpoints } from "../../enums";
import { axiosClient } from "helpers";

export const useGenerateTxt = () => {
  const generateTxtString = async (
    id: string | undefined,
    token: string | null
  ) => {
    try {
      const response = await axiosClient(token).post(
        ApiEndpoints.TRANSCRIPTION + "/" + id + "/text"
      );

      return response.data as string;
    } catch (error) {
      throw new Error("Login error");
    }
  };

  return { generateTxtString };
};
