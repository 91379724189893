import React, { FC } from 'react'
import { Subscription } from '../../../types'
import { User } from '../../../types/user.types'
import PrimaryButton from '../../common/PrimaryButton'
import { useCreatePortalSession } from '../../../hooks/request'
import { useAppSelector } from '../../../store/hooks'
import { Button } from 'antd'

interface Props {
  user: User
  subscription?: Subscription
}

const SubscriptionInfo: FC<Props> = ({ subscription, user }) => {
  const { token } = useAppSelector(state => state.auth)
  const { createPortalSession } = useCreatePortalSession(token)
  const {
    limit: { secondsLimitFromSubscription, additionalSeconds },
  } = user
  const remainingMinutes = Math.floor((secondsLimitFromSubscription + additionalSeconds) / 60)
  const currentPeriodEndDate = subscription
    ? new Date(subscription.currentPeriodEnd * 1000).toLocaleDateString('pl-PL')
    : ''

  const handlePortalSession = async () => {
    try {
      const response = await createPortalSession()
      window.location.href = response
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <p className="mb-1 text-lg text-blackText">Aktualny plan</p>
      <div className="w-2/3 bg-white rounded-xl py-8 px-16">
        <div className="mb-2">
          <p className="text-primaryBlue text-2xl font-semibold">{subscription?.name || 'FREE'}</p>
          <p className="text-blackText">{subscription?.description || ''}</p>
        </div>
        <ul className="list-disc list-inside mb-2">
          <li className="text-xs text-blackText">
            Maksymalna długość pliku audio:{' '}
            {subscription?.maxFileDuration ? subscription.maxFileDuration / 60 : 10} min.
          </li>
          <li className="text-xs text-blackText">
            Maksymalny rozmiar pliku audio:{' '}
            {subscription?.maxFileSize ? subscription.maxFileSize / 1000000 : 50} MB.
          </li>
        </ul>
        <p className="text-blackText">Pozostały dostępny czas: {remainingMinutes} min.</p>
        <div className="w-full flex flex-row items-end justify-between">
          {subscription?.currentPeriodEnd ? (
            <PrimaryButton onClick={handlePortalSession} title="Zarządzaj subskrypcją" />
          ) : null}
          <div className="flex flex-col items-end gap-y-4">
            {subscription?.currentPeriodEnd ? (
              <div>
                <p className="text-blackText">
                  Kolejny okres rozliczeniowy : {currentPeriodEndDate}
                </p>
              </div>
            ) : null}
            {subscription?.currentPeriodEnd ? (
              <Button className="w-48" onClick={handlePortalSession}>
                Anuluj subskrypcję
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionInfo
