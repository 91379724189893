import React from 'react'
import { ResetPasswordForm } from '../components/ResetPassword'
import { useNavigate } from 'react-router-dom'
import { AppPaths } from '../enums'

const ResetPassword = () => {
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(AppPaths.LOGIN)
  }
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex justify-center items-center flex-col w-[750px] shadow-default rounded-3xl bg-white px-12 pt-28 pb-20">
        <p className="mb-20 text-4xl text-center font-semibold">Resetuj hasło</p>
        <ResetPasswordForm />
        <p className="text-black mt-20">
          <span className="font-semibold cursor-pointer" onClick={handleBackClick}>
            Wróć do logowania
          </span>
        </p>
      </div>
    </div>
  )
}

export default ResetPassword
